import moment from "moment";
import React, { useRef, useState } from "react";

import { useCan } from "components";
import { useDealersLocations, useUser } from "hooks";
import { Appointment, Intervention, PIN_TYPE, PinModel, STATUS_IDENTIFIER } from "models";
import { INTERVENTION_TABS } from "modules/AppointmentDetails/components";
import { InterventionData, useIntervention } from "modules/AppointmentDetails/hooks";
import { useConnectedInterventionId } from "modules/AppointmentDetails/hooks/index";
import { isCarReadyOrQualityCheck } from "modules/AppointmentDetails/utils";

type GeneralInfoProps = {
  data: Intervention;
  appointment: Appointment | null;
  onPinTypeSet: (pinTypeID: PIN_TYPE) => void;
  typeID: number;
  handleTabSelection: (tab: INTERVENTION_TABS) => void;
  onSetHasChanges?: (change: boolean) => void;
};

export const useGeneralInfo = ({ data, appointment, onPinTypeSet, typeID, onSetHasChanges, handleTabSelection }: GeneralInfoProps) => {
  const user = useUser();
  const canUpdateInterventions = useCan("update", "interventions");
  const { selectedLocation } = useDealersLocations();
  const { updateConnectedInterventionId } = useConnectedInterventionId();
  const { updateIntervention, addPinItem, deletePinItem, deleteIntervention } = useIntervention(data?.appointment_id);

  const [disabled, setDisabled] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [deletePinReason, setDeletePinReason] = useState("");
  const [pinTypeID, setPinTypeID] = useState<PIN_TYPE>(typeID);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [customerOk, setCustomerOk] = useState(data.customer_ok || false);
  const [showDeleteInterventionModal, setShowDeleteInterventionModal] = useState(false);
  const tempFormEvent = useRef<React.FormEvent<HTMLFormElement>>();

  const isDisabled = appointment?.appointment_status_identifier === STATUS_IDENTIFIER.CanceledStatus || !canUpdateInterventions;
  const followDMS =
    !data.is_local && (appointment?.appointment_status_identifier === STATUS_IDENTIFIER.NewCarStatus || selectedLocation?.keep_importing_after_work_started);

  const cannotToggleCustomerOK = data.mechanic_fixed || Boolean(appointment && isCarReadyOrQualityCheck(appointment?.appointment_status_identifier));

  const toggleDropdown = () => setOpenDropdown(prev => !prev);
  const onRequestCloseModal = () => setShowConfirmModal(false);
  const handleDeleteLocalIntervention = () => setShowDeleteInterventionModal(true);
  const handleCancelDeleteLocalIntervention = () => setShowDeleteInterventionModal(false);

  const toggleCustomerOk = () => {
    setCustomerOk(prev => !prev);
    setDisabled(false);
    onSetHasChanges?.(true);
  };

  const handlePinUpdate = async (pinTypeID: PIN_TYPE) => {
    setPinTypeID(pinTypeID);
    onPinTypeSet(pinTypeID);

    if (pinTypeID === PIN_TYPE.NotSet) {
      await deletePinItem.mutateAsync({ intervention_id: data.id, note: deletePinReason });
      return;
    }

    if (pinTypeID) {
      const pin: PinModel = {
        pin_type_id: pinTypeID,
        order_status: null,
        intervention_id: data.id,
        appointment: null,
        appointment_id: data.appointment_id,
        intervention: null,
        user_id: user ? user.id : 0,
        user,
        question_result: null,
        note_attachments: [],
        created_on: moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")
      };

      await addPinItem.mutateAsync(pin);
      handleTabSelection(INTERVENTION_TABS.PinType);
    }
  };

  const handleConfirm = async () => {
    setShowConfirmModal(false);

    const formData: any = Object.fromEntries(new FormData((tempFormEvent.current as any).target));
    const intervention: InterventionData = {
      ...formData,
      price: Number(formData.price),
      customer_ok: customerOk,
      appointment_id: data.appointment_id,
      dms_nr: data.dms_nr,
      id: data.id,
      visible_to_customer: data.visible_to_customer
    };
    await updateIntervention.mutateAsync(intervention);

    setDisabled(true);
    onSetHasChanges?.(false);
  };

  const handleUpdateIntervention = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!data) return;

    tempFormEvent.current = e;
    handleConfirm();
  };

  const handleFormValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value != data[e.target.name as keyof Intervention]) {
      setDisabled(false);
      onSetHasChanges?.(true);
    } else {
      setDisabled(true);
      onSetHasChanges?.(false);
    }
  };

  const handleDeleteLocalInterventionConfirm = () => {
    deleteIntervention.mutate(data.id);
    setShowDeleteInterventionModal(false);
    updateConnectedInterventionId(null);
  };

  const handleButtonGroupClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();
    toggleDropdown();
  };

  return {
    disabled,
    pinTypeID,
    followDMS,
    isDisabled,
    customerOk,
    openDropdown,
    showConfirmModal,
    deleteIntervention,
    canUpdateInterventions,
    cannotToggleCustomerOK,
    showDeleteInterventionModal,
    handleConfirm,
    toggleDropdown,
    handlePinUpdate,
    toggleCustomerOk,
    setDeletePinReason,
    onRequestCloseModal,
    handleFormValueChange,
    handleButtonGroupClick,
    handleUpdateIntervention,
    handleDeleteLocalIntervention,
    handleCancelDeleteLocalIntervention,
    handleDeleteLocalInterventionConfirm
  };
};
