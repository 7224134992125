import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Icon } from "semantic-ui-react";

import { DetailsCards, useCan } from "components";
import { useDealersLocations } from "hooks/useDealersLocations";
import { Appointment, Intervention, STATUS_IDENTIFIER } from "models";
import {
  CustomerCommunicationInterventionItem,
  CustomerCommunicationPrice
} from "modules/AppointmentDetails/components/CustomerCommunicationModal/components/CustomerCommunicationInterventions/components";
import "modules/AppointmentDetails/components/CustomerCommunicationModal/components/CustomerCommunicationInterventions/CustomerCommunicationInterventions.scss";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { getPriceInVAT } from "util/common";
import { ITranslation } from "util/interfaces";

type CustomerCommunicationInterventionsProps = {
  appointment: Appointment;
  invalidInterventions?: boolean;
  showCustomerOk?: boolean;
  canToggleCustomerOk: boolean;
  isRepairOverviewStatus: boolean;
};

export const CustomerCommunicationInterventions = ({
  appointment,
  invalidInterventions,
  showCustomerOk,
  canToggleCustomerOk,
  isRepairOverviewStatus
}: CustomerCommunicationInterventionsProps) => {
  const [showVat, setShowVat] = useState(true);
  const { selectedLocation: location } = useDealersLocations();
  const canUpdateInterventions = useCan("update", "appointments");
  const { updateIntervention } = useIntervention(appointment.id);
  const t = useTranslation().t as ITranslation;

  const fixedInterventions = appointment?.interventions?.filter(intervention => intervention.mechanic_fixed);
  const notFixedInterventions = appointment?.interventions?.filter(intervention => !intervention.mechanic_fixed);

  const approvedInterventions = appointment?.interventions?.filter(intervention => intervention.customer_ok);
  const pendingInterventions = appointment?.interventions?.filter(intervention => !intervention.customer_ok);

  const totalAmountOfSelectedInterventions = useMemo(() => {
    if (!appointment) return 0;
    const eligibleInterventions = appointment.interventions?.filter(intervention => intervention.visible_to_customer) || [];
    const sum = eligibleInterventions.reduce((sum: number, intervention: Intervention) => sum + intervention.price, 0) || 0;
    return showVat ? getPriceInVAT(sum, location).toFixed(2) : sum.toFixed(2);
  }, [appointment, location, showVat]);

  const totalCustomerOkPrice = useMemo(() => {
    if (!appointment) return 0;
    const eligibleInterventions = appointment.interventions?.filter(intervention => intervention.customer_ok && intervention.visible_to_customer) || [];
    const sum = eligibleInterventions?.reduce((sum, intervention) => sum + intervention.price, 0) || 0;
    return showVat ? getPriceInVAT(sum, location).toFixed(2) : sum.toFixed(2);
  }, [appointment, location, showVat]);

  const totalFixedSelectedPriceInterventions = useMemo(() => {
    if (!appointment) return 0;

    const eligibleInterventions = appointment.interventions?.filter(intervention => intervention.mechanic_fixed && intervention.visible_to_customer) || [];
    const sum = eligibleInterventions?.reduce((sum, intervention) => sum + intervention.price, 0) || 0;

    return showVat ? getPriceInVAT(sum, location).toFixed(2) : sum.toFixed(2);
  }, [appointment, location, showVat]);

  const toggleVisibleInPDF = (item: Intervention) => {
    if (!canUpdateInterventions) return;

    if (appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      updateIntervention.mutate({
        ...item,
        visible_to_customer: !item.visible_to_customer
      });
    }
  };

  const toggleCustomerOk = (item: Intervention) => {
    if (!showCustomerOk) return;

    if (appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus) {
      updateIntervention.mutate({
        ...item,
        customer_ok: !item.customer_ok
      });
    }
  };

  return (
    <>
      <DetailsCards
        title={`${t("interventions").message || "Interventions"} ${appointment?.interventions?.length ? `(${appointment.interventions.length})` : ""}`}
        icon="screwdriver wrench"
        isExpanded={true}
        showExpandArrow={false}
        containerStyle={invalidInterventions ? "error" : ""}
        rightContent={
          <div className="full end">
            <p>{t("v8_show_prices_with_vat").message || "Show prices with VAT"}</p>
            <Checkbox toggle checked={showVat} onChange={() => setShowVat(prev => !prev)} />
          </div>
        }
      >
        <div className="CustomerCommunicationInterventions">
          <div className="CustomerCommunicationInterventions-container">
            {!!(isRepairOverviewStatus ? notFixedInterventions : pendingInterventions)?.length && (
              <div className="CustomerCommunicationInterventions-section">
                <div className="CustomerCommunicationInterventions-section-group">
                  <div className="CustomerCommunicationInterventions-section-group_header">
                    {isRepairOverviewStatus && <Icon className="wrench regular grey rotate-270" />}
                    {!isRepairOverviewStatus && <Icon className="crossIcon circle xmark outline red" />}
                    {isRepairOverviewStatus && <p>{t("v8_not_fixed").message || "Not Fixed"}</p>}
                    {!isRepairOverviewStatus && <p>{t("v8_approval_pending").message || "Approval Pending"}</p>}
                  </div>
                  {(isRepairOverviewStatus ? notFixedInterventions : pendingInterventions)?.map((item: Intervention) => {
                    return (
                      <CustomerCommunicationInterventionItem
                        key={item.id}
                        intervention={item}
                        toggleVisibleInPDF={toggleVisibleInPDF}
                        showVat={showVat}
                        canToggleCustomerOk={canToggleCustomerOk}
                        {...((showCustomerOk || isRepairOverviewStatus) && { toggleCustomerOk })}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {!!(isRepairOverviewStatus ? fixedInterventions : approvedInterventions)?.length && (
              <div className="CustomerCommunicationInterventions-section">
                <div className="CustomerCommunicationInterventions-section-group">
                  <div className="CustomerCommunicationInterventions-section-group_header">
                    {!isRepairOverviewStatus && <Icon className="check circle outline green" />}
                    {isRepairOverviewStatus && (
                      <div className="mechanic-fixed-status-container">
                        <Icon className="wrench green pointer" />
                        <Icon className="check circle green wrench-check-icon" />
                      </div>
                    )}
                    {!isRepairOverviewStatus && <p>{t("v8_approved").message || "Approved"}</p>}
                    {isRepairOverviewStatus && <p>{t("v8_fixed").message || "Fixed"}</p>}
                  </div>
                  {(isRepairOverviewStatus ? fixedInterventions : approvedInterventions)?.map((item: Intervention) => {
                    return (
                      <CustomerCommunicationInterventionItem
                        key={item.id}
                        intervention={item}
                        toggleVisibleInPDF={toggleVisibleInPDF}
                        showVat={showVat}
                        canToggleCustomerOk={canToggleCustomerOk}
                        {...((showCustomerOk || isRepairOverviewStatus) && { toggleCustomerOk })}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <CustomerCommunicationPrice
            isRepairOverviewStatus={isRepairOverviewStatus}
            showCustomerOk={showCustomerOk}
            totalAmountOfSelectedInterventions={totalAmountOfSelectedInterventions}
            totalFixedSelectedPriceInterventions={totalFixedSelectedPriceInterventions}
            totalCustomerOkPrice={totalCustomerOkPrice}
          />
        </div>
      </DetailsCards>
    </>
  );
};
