import { OnChangeFn, PaginationState, createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Loader } from "semantic-ui-react";

import {
  ANSWER_STATUS_SELECTOR_TYPE,
  ActionModalSelectorData,
  DATE_FORMATS,
  ReactTable,
  useGetSnoozeDepartmentData,
  useGetSnoozeStatusData,
  useGetSnoozeTypeData
} from "components";
import { Appointment, SnoozeItem } from "models";
import SnoozeActions from "modules/AppointmentDetails/components/SnoozedItems/SnoozeActions";
import { CustomerCard } from "modules/LeadsDashboard/components";
import { ITranslation } from "util/interfaces";

interface SnoozeTableProps {
  snoozeItems: SnoozeItem[];
  activePage: number;
  totalPages: number;
  totalItems: number;
  loading: boolean;
  onPageChange: (data: { activePage: number }) => void;
  onSelect: (data: ActionModalSelectorData) => void;
  onUpdateAnswerStatus?: (id: number, status: number, type: ANSWER_STATUS_SELECTOR_TYPE) => void;
  appointment: Appointment;
}

const PAGE_SIZE = 10;

export const SnoozeTable = ({
  snoozeItems,
  activePage,
  totalPages,
  totalItems,
  loading,
  onPageChange,
  onSelect,
  onUpdateAnswerStatus,
  appointment
}: SnoozeTableProps) => {
  const t = useTranslation().t as ITranslation;
  const [isExpanded, setIsExpanded] = useState(false);
  const { allSnoozeTypeData } = useGetSnoozeTypeData();
  const snoozeStatusData = useGetSnoozeStatusData();
  const snoozeDepartmentData = useGetSnoozeDepartmentData();

  const columnHelper = createColumnHelper<SnoozeItem>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: activePage - 1,
    pageSize: totalPages
  });

  const handlePaginationChange: OnChangeFn<PaginationState> = updater => {
    setPagination(old => {
      const newState = typeof updater === "function" ? updater(old) : updater;
      onPageChange({ activePage: newState.pageIndex + 1 });
      return newState;
    });
  };

  const columns = [
    columnHelper.accessor(row => row, {
      id: "title",
      header: t("v8_title").message || "Title",
      cell: info => {
        const snoozeItem = info.renderValue();
        return <span>{snoozeItem?.question_result?.title || snoozeItem?.intervention?.title || ""}</span>;
      },
      size: 175
    }),
    columnHelper.accessor(row => row.snooze_type_id, {
      id: "snooze_type_id",
      header: t("v8_snooze_type").message || "Snooze type",
      cell: info => <span>{allSnoozeTypeData.find(type => type.value === info.renderValue())?.text || ""}</span>
    }),
    columnHelper.accessor(row => row.snooze_date, {
      id: "snooze_date",
      header: t("v8_snooze_date").message || "Snooze date",
      cell: info => <span>{info.renderValue() ? moment(info.renderValue()).format(DATE_FORMATS.dateMonthYear) : ""}</span>
    }),
    columnHelper.accessor(row => row.snooze_status_id, {
      id: "snooze_status_id",
      header: t("v8_snooze_status").message || "Snooze status",
      cell: info => <span>{snoozeStatusData.find(status => status.value === info.renderValue())?.text || ""}</span>
    }),
    columnHelper.accessor(row => row.snooze_department_id, {
      id: "snooze_department_id",
      header: t("v8_department").message || "Department",
      cell: info => <span>{snoozeDepartmentData.find(status => status.value === info.renderValue())?.text || ""}</span>
    }),
    columnHelper.accessor(row => row.note, {
      id: "note",
      header: t("v8_note").message || "Note",
      cell: info => <span>{info.renderValue()}</span>,
      size: 200
    }),
    columnHelper.accessor(row => row, {
      id: "wo",
      header: "WO #",
      cell: info => {
        const snoozeItem = info.renderValue();
        if (!snoozeItem?.appointment) return null;
        return (
          <span>
            <Link to={`/appointments/${snoozeItem.appointment.id}`} target="_blank">
              <Icon className="arrow up right from square" />
              {snoozeItem.appointment.wo_nr || ""}
            </Link>
          </span>
        );
      },
      size: 100
    }),
    columnHelper.accessor(row => row, {
      id: "action",
      header: "",
      cell: info => {
        const snoozeItem = info.renderValue();
        if (!snoozeItem?.appointment) return null;
        return (
          <div className="action-container">
            <CustomerCard
              contractor={snoozeItem.appointment.customer_contract}
              owner={snoozeItem.appointment.customer_owner}
              driver={snoozeItem.appointment.customer_driver}
            />
            <SnoozeActions
              data={snoozeItem.question_result || snoozeItem.intervention}
              isIntervention={!!snoozeItem.intervention_id}
              onSelect={onSelect}
              snoozeItem={snoozeItem}
              appointment={appointment}
              onUpdateAnswerStatus={onUpdateAnswerStatus}
            />
          </div>
        );
      },
      size: 100
    })
  ];

  if (loading) return <Loader active />;
  if (!snoozeItems.length) return null;

  return (
    <div className={`SnoozeTable ${totalPages > 1 ? "withPagination" : ""}`}>
      <div className={`SnoozeTable-header ${isExpanded ? "expanded" : ""}`}>
        <h3>{`${t("v8_snoozed_items_history").message || "Snoozed Items History"} ${totalItems > 0 ? `(${totalItems})` : ""}`}</h3>
        <Icon className={`chevron ${isExpanded ? "down" : "right"} pointer`} onClick={() => setIsExpanded(prev => !prev)} />
      </div>
      {isExpanded && (
        <ReactTable
          columns={columns}
          data={snoozeItems}
          pageCount={totalPages}
          pagination={totalPages > 1 ? pagination : undefined}
          onPaginationChange={handlePaginationChange}
          totalItems={totalItems}
          pageSize={PAGE_SIZE}
        />
      )}
    </div>
  );
};
