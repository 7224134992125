import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";

import { useDealersLocations, useRealTimeQuery } from "hooks";
import { useDealerLicenseOverviewListeners } from "modules/DealerLicenseOverview/listeners";
import { DealerLicenseQueryKeys } from "modules/DealerLicenseOverview/queryKeys";
import { HistoricalInvoice, LicenseItem, PaginatedHistoricalInvoices } from "modules/LocationLicenseOverview/types";
import { transformData } from "modules/LocationLicenseOverview/utils";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

type LicenseItems = Record<"items", LicenseItem[]>;

type DealerLicenseItemsResponse = {
  data: LicenseItems;
};

type PaginatedInvoice = {
  dealer: number;
  data: Record<number, PaginatedHistoricalInvoices>;
};

type PaginatedInvoicesType = {
  data: PaginatedInvoice[];
};

export const useDealerItems = () => {
  const { selectedDealer } = useDealersLocations();

  const getItems = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    const { baseUrl, endpoint, params } = queryKey[0];
    const response: DealerLicenseItemsResponse = await ApiInstance.post(endpoint, params, baseUrl);
    return response.data;
  };

  const { data: invoiceData, isLoading } = useQuery({
    queryKey: DealerLicenseQueryKeys.licenseItems({ dealerId: selectedDealer?.id }),
    queryFn: getItems,
    retry: true,
    enabled: !!selectedDealer?.id
  });

  return { dealerItems: invoiceData?.items || [], isLoadingDealerItems: isLoading };
};

export const useDealerInvoices = (paginationUrl = "", currentInvoicePage = 0) => {
  const { data: paginatedInvoices } = useQuery<PaginatedInvoicesType>({ queryKey: DealerLicenseQueryKeys.paginatedInvoices, initialData: { data: [] } });
  const { selectedDealer } = useDealersLocations();
  const queryClient = useQueryClient();

  const getSortedResults = (data: HistoricalInvoice[]) => [...data].sort((a, b) => moment(b.Modified, "DD/MM/YYYY").diff(moment(a.Modified, "DD/MM/YYYY")));

  const updatePaginatedInvoices = (data: PaginatedHistoricalInvoices) => {
    const existingDealer = paginatedInvoices?.data.find(element => element.dealer === selectedDealer?.id);

    if (!existingDealer) {
      const newDealer = { dealer: selectedDealer?.id, data: { [currentInvoicePage]: data } };
      return { data: [...(paginatedInvoices?.data || []), newDealer] };
    }

    const updatedData = { ...paginatedInvoices };
    const existingDealerIndex = updatedData.data.findIndex(element => element.dealer === selectedDealer?.id);

    if (existingDealerIndex !== -1) {
      updatedData.data[existingDealerIndex] = { ...existingDealer, data: { ...existingDealer.data, [currentInvoicePage]: data } };
    }

    return { data: updatedData.data };
  };

  const getInvoices = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    if (paginatedInvoices) {
      const existingDealerData = paginatedInvoices.data.find(element => element.dealer === selectedDealer?.id);

      if (existingDealerData?.data[currentInvoicePage]) {
        return existingDealerData?.data[currentInvoicePage] as PaginatedHistoricalInvoices;
      }
    }

    const { baseUrl, endpoint, params } = queryKey[0];
    const response = await ApiInstance.post(endpoint, params, baseUrl);
    const data = response.data as PaginatedHistoricalInvoices;

    queryClient.setQueryData(DealerLicenseQueryKeys.paginatedInvoices, updatePaginatedInvoices(data));

    return {
      __next: data.__next,
      results: getSortedResults(data.results)
    };
  };

  const { data, error, isLoading } = useQuery({
    queryKey: DealerLicenseQueryKeys.invoices({
      dealer_id: selectedDealer?.id,
      pagination_url: paginationUrl || undefined,
      page_index: currentInvoicePage
    }),
    queryFn: getInvoices,
    enabled: !!selectedDealer?.id
  });

  return { dealerInvoices: data, paginatedDealerInvoices: paginatedInvoices, isLoadingDealerInvoices: isLoading, errorDealerInvoices: error };
};

export const useDealerLicenses = () => {
  const { selectedDealer } = useDealersLocations();
  const listeners = useDealerLicenseOverviewListeners({ selectedDealer });

  const getLicenses = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    if (!selectedDealer?.id) return;

    const { baseUrl, endpoint, params } = queryKey[0];
    const res = await ApiInstance.post(endpoint, params, baseUrl);
    return transformData(res.data);
  };

  const realTimeQuery = useRealTimeQuery({
    queryKey: DealerLicenseQueryKeys.licenses({ dealerId: selectedDealer?.id }),
    queryFn: getLicenses,
    listeners,
    enabled: !!selectedDealer?.id
  });

  return {
    dealerLicenses: realTimeQuery.data,
    isLoadingDealerLicenses: realTimeQuery.isFetching,
    dealerLicensesError: realTimeQuery.error
  };
};
