import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Icon, Input, Modal } from "semantic-ui-react";

import { AppointmentNoteModal, useCan } from "components";
import { NoteTypeIcon } from "components/Notes";
import { Appointment, AppointmentNote } from "models";
import { CustomerCommunicationInterventions } from "modules/AppointmentDetails/components/CustomerCommunicationModal/components";
import "modules/AppointmentDetails/components/DeskCommunication/components/DeskCommunicationModal/DeskCommunicationModal.scss";
import { useCustomerWaitingHandle } from "modules/AppointmentDetails/components/DeskCommunication/hooks/useCustomerWaitingHandle";
import { DESK_NOTE_TYPES, DeskNoteType } from "modules/AppointmentDetails/components/DeskCommunication/types";
import { isCarReadyOrQualityCheckDoneOrCanceled } from "util/common";
import { ITranslation } from "util/interfaces";

type DeskCommunicationModalProps = {
  appointment: Appointment;
  onClose: () => void;
  onSend: () => void;
};

export const DeskCommunicationModal = ({ appointment, onClose, onSend }: DeskCommunicationModalProps) => {
  const [customerWaiting, setCustomerWaiting] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<AppointmentNote | null>(null);
  const [noteType, setNoteType] = useState<number | null>(null);
  const [woNoteId, setWoNoteId] = useState<number | null>(null);
  const [temporaryDriverNoteId, setTemporaryDriverNoteId] = useState<number | null>(null);

  const t = useTranslation().t as ITranslation;

  const { customerWaitingMutation, isLoading } = useCustomerWaitingHandle({ appointment_id: appointment.id, customer_waiting: customerWaiting });

  const handleSelectNote = (appointmentNoteTypeId: number | null) => {
    if (!appointmentNoteTypeId) return;

    const foundNote = appointment.notes?.find(note => note.appointment_note_type_id === appointmentNoteTypeId);
    if (foundNote && [DESK_NOTE_TYPES.Parking, DESK_NOTE_TYPES.Main].includes(foundNote.appointment_note_type_id)) setSelectedNote(foundNote);

    setNoteType(appointmentNoteTypeId);
  };

  const closeNoteModal = () => {
    setNoteType(null);
    setSelectedNote(null);
  };

  const handleSaveNote = (appointmentNoteTypeId: number, id: number) => {
    if (appointmentNoteTypeId === DESK_NOTE_TYPES.Wo) setWoNoteId(id);
    if (appointmentNoteTypeId === DESK_NOTE_TYPES.TemporaryDriverNote) setTemporaryDriverNoteId(id);
  };

  const handleCustomerWaitingChange = () => setCustomerWaiting(prev => !prev);

  const handleSendDeskComm = () => {
    if (customerWaiting !== appointment.customer_waiting) customerWaitingMutation();
    onSend();
  };

  const isCarReadyOrQualityCheckDone = isCarReadyOrQualityCheckDoneOrCanceled(appointment);

  const canCreateNote = {
    [DESK_NOTE_TYPES.Parking]: useCan(["create", "update"], "appointment-notes-parking-location") && !isCarReadyOrQualityCheckDone,
    [DESK_NOTE_TYPES.Main]: useCan(["create", "update"], "appointment-notes") && !isCarReadyOrQualityCheckDone,
    [DESK_NOTE_TYPES.Wo]: useCan("create", "appointment-notes-wo") && !isCarReadyOrQualityCheckDone,
    [DESK_NOTE_TYPES.TemporaryDriverNote]: useCan("create", "appointment-notes-temp-driver") && !isCarReadyOrQualityCheckDone
  };

  const getNoteValue = (noteType: DeskNoteType) => {
    const note = appointment.notes?.find(n => {
      if (noteType === DESK_NOTE_TYPES.Wo) return n.id === woNoteId;
      if (noteType === DESK_NOTE_TYPES.TemporaryDriverNote) return n.id === temporaryDriverNoteId;
      return n.appointment_note_type_id === noteType;
    });

    return note?.note || "";
  };

  const getPlaceholderValue = (noteType: DeskNoteType) => {
    switch (noteType) {
      case DESK_NOTE_TYPES.Main:
        return t("v8_main_note").message || "Main note";
      case DESK_NOTE_TYPES.Parking:
        return t("v8_parking_note").message || "Parking note";
      case DESK_NOTE_TYPES.TemporaryDriverNote:
        return t("v8_temporary_driver_note").message || "Temporary Driver Note";
      case DESK_NOTE_TYPES.Wo:
      default:
        return t("v8_note").message || "Note";
    }
  };

  return (
    <Modal open className="DeskCommunicationModal" size="fullscreen" dimmer="blurring" onClose={onClose}>
      <Modal.Header className="DeskCommunicationModal__header">
        {isCarReadyOrQualityCheckDone ? t("v8_desk_check_out").message || "Desk Check-out" : t("v8_desk_check_in").message || "Desk Check-in"}

        <Button className="-appointment-status" size="small" onClick={onClose}>
          <Icon className="xmark grey" size="big" />
        </Button>
      </Modal.Header>
      <Modal.Content>
        <div className="DeskCommunicationModal__content">
          <div className="notes-container">
            {(Object.values(DESK_NOTE_TYPES) as DeskNoteType[]).map(noteType => (
              <div key={noteType} className="notes-container-input">
                <NoteTypeIcon type={noteType} />
                <div className="clickable-input-wrapper">
                  <Input
                    placeholder={getPlaceholderValue(noteType)}
                    value={getNoteValue(noteType)}
                    onClick={() => handleSelectNote(noteType)}
                    readOnly
                    disabled={!canCreateNote[noteType]}
                  />
                </div>
              </div>
            ))}

            <div className="notes-container-checkbox">
              <label>
                <Icon className="mug saucer" />
                {t("v8_customer_waiting").message || "Customer Waiting"}
              </label>

              <Checkbox checked={customerWaiting} onChange={handleCustomerWaitingChange} disabled={isCarReadyOrQualityCheckDone} />
            </div>
          </div>

          <div className="interventions-container">
            <CustomerCommunicationInterventions appointment={appointment} showCustomerOk canToggleCustomerOk isRepairOverviewStatus={false} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions className="DeskCommunicationModal__actions">
        <Button loading={isLoading} disabled={isLoading} color="green" className="save-btn" onClick={handleSendDeskComm}>
          {t("v8_send").message || "Send"}
          <Icon className="check" />
        </Button>
      </Modal.Actions>

      {Boolean(noteType) && (
        <AppointmentNoteModal
          selectedNote={selectedNote}
          noteType={noteType}
          onSetSelectedAppointmentNote={selectedNote => handleSelectNote(selectedNote?.appointment_note_type_id || null)}
          onUnselectNote={closeNoteModal}
          resetNoteType={closeNoteModal}
          appointment={appointment}
          onSave={handleSaveNote}
        />
      )}
    </Modal>
  );
};
