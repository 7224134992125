import { useTranslation } from "react-i18next";

import { ITranslation } from "util/interfaces";

interface CustomerCommunicationPriceProps {
  isRepairOverviewStatus: boolean;
  totalAmountOfSelectedInterventions: number | string;
  totalCustomerOkPrice: number | string;
  totalFixedSelectedPriceInterventions: number | string;
  showCustomerOk?: boolean;
}

export const CustomerCommunicationPrice = ({
  isRepairOverviewStatus,
  totalAmountOfSelectedInterventions,
  totalCustomerOkPrice,
  totalFixedSelectedPriceInterventions,
  showCustomerOk
}: CustomerCommunicationPriceProps) => {
  const t = useTranslation().t as ITranslation;
  return (
    <div className="total-amount-container">
      <div className="float-right">
        {isRepairOverviewStatus && (
          <p>
            {t("v8_fixed_and_selected").message || "Fixed & Selected"}: <strong>€ {totalFixedSelectedPriceInterventions}</strong>
          </p>
        )}

        {!isRepairOverviewStatus && (
          <p>
            {t("v8_total_amount_of_selected_items").message || "Total amount of selected items"}: <strong>€ {totalAmountOfSelectedInterventions}</strong>
          </p>
        )}
      </div>
      {!isRepairOverviewStatus && showCustomerOk && (
        <div className="float-right">
          <p>
            {t("v8_customer_approved_total").message || "Customer approved total"}: <strong>€ {totalCustomerOkPrice}</strong>
          </p>
        </div>
      )}
    </div>
  );
};
