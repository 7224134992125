import { QueryFunctionContext, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { WebSocketMessageListener } from "components";
import { useRealTimeQuery } from "hooks";
import {
  Appointment,
  AppointmentNote,
  AppointmentStatusHistory,
  Car,
  CarNote,
  CheckEvent,
  CheckInCommunicationResult,
  CommunicationAgreement,
  CommunicationEvent,
  CommunicationReceiver,
  CustomerCommunication,
  CustomerCommunicationIntervention,
  DeskCommunication,
  DeskCommunicationEvent,
  DiagnoseOverviewAgreedResult,
  DiagnoseOverviewContactResult,
  DiagnoseOverviewDeclinedResult,
  Intervention,
  KeylockerRemark,
  KioskCommunication,
  KioskCommunicationEvent,
  KioskRemark,
  PinModel
} from "models";
import { AcsesCommunicationReceiver } from "models/AcsesCommunication";
import { useConnectedInterventionId } from "modules/AppointmentDetails/hooks/index";
import { AppointmentDetailsKeys } from "modules/AppointmentDetails/queryKeys";
import { useDesktopNotifications } from "modules/Auth/components/AccountSettings/hooks/index";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

export const getAppointment = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
  const { baseUrl, endpoint, params } = queryKey[0];
  const res = await ApiInstance.post(endpoint, params, baseUrl);

  const appointment: Appointment = res.data;
  appointment.status_history?.sort((a, b) => a.created_on.localeCompare(b.created_on));

  return appointment;
};

export const useAppointment = (id: number) => {
  const queryClient = useQueryClient();
  const appointmentDetailsViewKey = AppointmentDetailsKeys.view(id);
  const { updateConnectedInterventionId } = useConnectedInterventionId();
  const { processDesktopNotifications } = useDesktopNotifications();

  const listeners = useMemo((): WebSocketMessageListener[] => {
    return [
      {
        model: "Appointment",
        action: "update",
        id: Number(id),
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment) return;
          processDesktopNotifications(appointment, message.data as Appointment, message.user_id);
          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, ...(message.data as Appointment) });
        }
      },
      {
        model: "Car",
        action: "update",
        callback: message => {
          const car = message.data as Car;
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (appointment?.car_id !== car.id) return;

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, car: { ...appointment.car, ...car } });
        }
      },
      {
        model: "Pin",
        action: "append",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.interventions?.length) return;

          const pin = message.data as PinModel;
          const interventionIdx = appointment.interventions.findIndex(i => i.id === pin.intervention_id);
          if (interventionIdx < 0 || appointment.interventions[interventionIdx].pin_history?.some(p => p.id === pin.id)) return;

          const intervention = appointment.interventions[interventionIdx];
          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            interventions: appointment.interventions.with(interventionIdx, { ...intervention, pin_history: [...(intervention.pin_history ?? []), pin] })
          });
        }
      },
      {
        model: "Pin",
        action: "delete",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          const interventions = appointment?.interventions;
          if (!interventions?.length) return;

          const pin = message.data as PinModel;
          const interventionIdx = interventions.findIndex(i => i.id === pin.intervention_id);
          if (interventionIdx < 0) return;

          const intervention = interventions[interventionIdx];
          const history = intervention.pin_history;
          if (!history?.length) return;

          const pinIdx = history.findIndex(p => p.id === pin.id);
          if (pinIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            interventions: interventions.with(interventionIdx, { ...intervention, pin_history: history.slice(0, pinIdx).concat(history.slice(pinIdx + 1)) })
          });
        }
      },
      {
        model: "AppointmentNote",
        action: "create", // TODO should be append
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment) return;

          const note = message.data as AppointmentNote;
          if (appointment.notes?.some(n => n.id === note.id)) return;

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, notes: [...(appointment.notes ?? []), note] });
        }
      },
      {
        model: "AppointmentNote",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.notes?.length) return;

          const note = message.data as AppointmentNote;
          const noteIdx = appointment.notes.findIndex(n => n.id === note.id);
          if (noteIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, notes: appointment.notes.with(noteIdx, { ...appointment.notes[noteIdx], ...note }) });
        }
      },
      {
        model: "AppointmentNote",
        action: "delete",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.notes?.length) return;

          const note = message.data as AppointmentNote;
          const noteIdx = appointment.notes.findIndex(n => n.id === note.id);
          if (noteIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            notes: appointment.notes.slice(0, noteIdx).concat(appointment.notes.slice(noteIdx + 1))
          });
        }
      },
      {
        model: "Intervention",
        action: "create",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          const intervention = message.data as Intervention;
          if (!appointment || appointment.interventions?.some(i => i.id === intervention.id)) return;

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, interventions: [...(appointment.interventions ?? []), intervention] });
        }
      },
      {
        model: "Intervention",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.interventions?.length) return;

          const intervention = message.data as Intervention;
          const interventionIdx = appointment.interventions.findIndex(i => i.id === intervention.id);
          if (interventionIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            interventions: appointment.interventions.with(interventionIdx, { ...appointment.interventions[interventionIdx], ...intervention })
          });
        }
      },
      {
        model: "Intervention",
        action: "delete",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.interventions?.length) return;

          const intervention = message.data as Intervention;
          const interventionIdx = appointment.interventions.findIndex(i => i.id === intervention.id);
          if (interventionIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            interventions: appointment.interventions.slice(0, interventionIdx).concat(appointment.interventions.slice(interventionIdx + 1))
          });
          updateConnectedInterventionId(null);
        }
      },
      {
        model: "KeyLockerRemark",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.keylocker_communications?.length) return;

          const remark = message.data as KeylockerRemark;
          const commIdx = appointment.keylocker_communications.findIndex(kc => kc.id === remark.keylocker_communication_id);
          if (commIdx < 0) return;

          const comm = appointment.keylocker_communications[commIdx];
          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            keylocker_communications: appointment.keylocker_communications.with(commIdx, { ...comm, remark: { ...comm.remark, ...remark } })
          });
        }
      },
      {
        model: "KioskRemark",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.kiosk_communications?.length) return;

          const remark = message.data as KioskRemark;
          const commIdx = appointment.kiosk_communications.findIndex(kc => kc.id === remark.kiosk_communication_id);
          if (commIdx < 0) return;

          const comm = appointment.kiosk_communications[commIdx];
          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            kiosk_communications: appointment.kiosk_communications.with(commIdx, { ...comm, remark: { ...comm.remark, ...remark } })
          });
        }
      },
      {
        model: "AppointmentStatusHistory",
        action: "create",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const history = message.data as AppointmentStatusHistory;
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment || appointment.status_history?.some(h => h.id === history.id)) return;

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, status_history: [...(appointment.status_history ?? []), history] });
        }
      },
      {
        model: "AppointmentStatusHistory",
        action: "append",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const history = message.data as AppointmentStatusHistory;
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment || appointment.status_history?.some(h => h.id === history.id)) return;

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, status_history: [...(appointment.status_history ?? []), history] });
        }
      },
      {
        model: "AppointmentStatusHistory",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.status_history?.length) return;

          const history = message.data as AppointmentStatusHistory;
          const historyIdx = appointment.status_history.findIndex(h => h.id === history.id);
          if (historyIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            status_history: appointment.status_history.with(historyIdx, { ...appointment.status_history[historyIdx], ...history })
          });
        }
      },
      {
        model: "CustomerCommunication",
        filter: { appointment_id: Number(id) },
        action: "create",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment || appointment.customer_communication) return;

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, customer_communication: message.data });
        }
      },
      {
        model: "CustomerCommunication",
        filter: { appointment_id: Number(id) },
        action: "update",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment || !appointment.customer_communication) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: { ...appointment.customer_communication, ...(message.data as CustomerCommunication) }
          });
        }
      },
      {
        model: "CustomerCommunication",
        filter: { appointment_id: Number(id) },
        action: "upsert",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: { ...appointment.customer_communication, ...(message.data as CustomerCommunication) }
          });
        }
      },
      {
        model: "CommunicationEvent",
        filter: { appointment_id: Number(id) },
        action: "append",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.customer_communication) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: { ...appointment.customer_communication, events: [...(appointment.customer_communication.events ?? []), message.data] }
          });
        }
      },
      {
        model: "CommunicationEvent",
        filter: { appointment_id: Number(id) },
        action: "upsert",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.customer_communication) return;

          const event = message.data as CommunicationEvent;
          const events = [...(appointment.customer_communication.events ?? [])];
          const idx = events.findIndex(e => e.id === event.id);
          if (idx >= 0) events[idx] = { ...events[idx], ...event };
          else events.push(event);

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, customer_communication: { ...appointment.customer_communication, events } });
        }
      },
      {
        model: "CheckInResult",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          const comm = appointment?.customer_communication;
          if (!comm?.check_in_results?.length) return;

          const result = message.data as CheckInCommunicationResult;
          const resultIdx = comm.check_in_results.findIndex(r => r.id === result.id);
          if (resultIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: { ...comm, check_in_results: comm.check_in_results.with(resultIdx, { ...comm.check_in_results[resultIdx], ...result }) }
          });
        }
      },
      {
        model: "CheckInRemark",
        action: "append",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          const comm = appointment?.customer_communication;
          if (!comm) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: { ...comm, check_in_remarks: [...(comm.check_in_remarks ?? []), message.data] }
          });
        }
      },
      {
        model: "CommunicationReceiver",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.customer_communication?.events?.length) return;

          const events = appointment?.customer_communication?.events;
          const receiver = message.data as CommunicationReceiver;
          const eventIdx = events.findIndex(e => e.id === receiver.communication_event_id);
          if (eventIdx < 0) return;

          const receivers = events[eventIdx].receivers;
          if (!receivers?.length) return;

          const receiverIdx = receivers.findIndex(r => r.id === receiver.id);
          if (receiverIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: {
              ...appointment.customer_communication,
              events: events.with(eventIdx, { ...events[eventIdx], receivers: receivers.with(receiverIdx, { ...receivers[receiverIdx], ...receiver }) })
            }
          });
        }
      },
      {
        model: "CustomerCommunicationIntervention",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.customer_communication?.events?.length) return;

          const events = appointment?.customer_communication?.events;
          const result = message.data as CustomerCommunicationIntervention;
          const eventIdx = events.findIndex(e => e.id === result.communication_event_id);
          if (eventIdx < 0) return;

          const results = events[eventIdx].intervention_results;
          if (!results?.length) return;

          const resultIdx = results.findIndex(r => r.id === result.id);
          if (resultIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: {
              ...appointment.customer_communication,
              events: events.with(eventIdx, { ...events[eventIdx], intervention_results: results.with(resultIdx, { ...results[resultIdx], ...result }) })
            }
          });
        }
      },
      {
        model: "CommunicationAgreement",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.customer_communication?.events?.length) return;

          const events = appointment?.customer_communication?.events;
          const agreement = message.data as CommunicationAgreement;
          const eventIdx = events.findIndex(e => e.id === agreement.communication_event_id);
          if (eventIdx < 0) return;

          const agreements = events[eventIdx].agreements;
          if (!agreements?.length) return;

          const agreementIdx = agreements.findIndex(r => r.id === agreement.id);
          if (agreementIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: {
              ...appointment.customer_communication,
              events: events.with(eventIdx, { ...events[eventIdx], agreements: agreements.with(agreementIdx, { ...agreements[agreementIdx], ...agreement }) })
            }
          });
        }
      },
      {
        model: "DiagnoseOverviewRemark",
        action: "append",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          const comm = appointment?.customer_communication;
          if (!comm) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: { ...comm, diagnose_overview_remarks: [...(comm.diagnose_overview_remarks ?? []), message.data] }
          });
        }
      },
      {
        model: "DiagnoseOverviewAgreedResult",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.customer_communication?.events?.length) return;

          const events = appointment?.customer_communication?.events;
          const result = message.data as DiagnoseOverviewAgreedResult;
          const eventIdx = events.findIndex(e => e.id === result.communication_event_id);
          if (eventIdx < 0) return;

          const results = events[eventIdx].diagnose_overview_agreed_results;
          if (!results?.length) return;

          const resultIdx = results.findIndex(r => r.id === result.id);
          if (resultIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: {
              ...appointment.customer_communication,
              events: events.with(eventIdx, { ...events[eventIdx], diagnose_overview_agreed_results: results.with(resultIdx, { ...results[resultIdx], ...result }) })
            }
          });
        }
      },
      {
        model: "DiagnoseOverviewDeclinedResult",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.customer_communication?.events?.length) return;

          const events = appointment?.customer_communication?.events;
          const result = message.data as DiagnoseOverviewDeclinedResult;
          const eventIdx = events.findIndex(e => e.id === result.communication_event_id);
          if (eventIdx < 0) return;

          const results = events[eventIdx].diagnose_overview_declined_results;
          if (!results?.length) return;

          const resultIdx = results.findIndex(r => r.id === result.id);
          if (resultIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: {
              ...appointment.customer_communication,
              events: events.with(eventIdx, { ...events[eventIdx], diagnose_overview_declined_results: results.with(resultIdx, { ...results[resultIdx], ...result }) })
            }
          });
        }
      },
      {
        model: "DiagnoseOverviewContactResult",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.customer_communication?.events?.length) return;

          const events = appointment?.customer_communication?.events;
          const result = message.data as DiagnoseOverviewContactResult;
          const eventIdx = events.findIndex(e => e.id === result.communication_event_id);
          if (eventIdx < 0) return;

          const results = events[eventIdx].diagnose_overview_contact_results;
          if (!results?.length) return;

          const resultIdx = results.findIndex(r => r.id === result.id);
          if (resultIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            customer_communication: {
              ...appointment.customer_communication,
              events: events.with(eventIdx, { ...events[eventIdx], diagnose_overview_contact_results: results.with(resultIdx, { ...results[resultIdx], ...result }) })
            }
          });
        }
      },
      {
        model: "CarNote",
        action: "create",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.car) return;

          const note = message.data as CarNote;
          if (appointment.car.notes?.some(n => n.id === note.id)) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            car: {
              ...appointment.car,
              notes: [...(appointment.car.notes ?? []), note]
            }
          });
        }
      },
      {
        model: "CarNote",
        action: "update",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.car?.notes?.length) return;

          const note = message.data as CarNote;
          const noteIdx = appointment.car.notes.findIndex(n => n.id === note.id);
          if (noteIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            car: {
              ...appointment.car,
              notes: appointment.car.notes.with(noteIdx, { ...appointment.car.notes[noteIdx], ...note })
            }
          });
        }
      },
      {
        model: "CarNote",
        action: "upsert",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.car) return;

          const note = message.data as CarNote;
          const notes = [...(appointment.car.notes ?? [])];
          const noteIdx = notes.findIndex(n => n.id === note.id);

          if (noteIdx >= 0) notes[noteIdx] = { ...notes[noteIdx], ...note };
          else notes.push(note);

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            car: {
              ...appointment.car,
              notes
            }
          });
        }
      },
      {
        model: "CarNote",
        action: "delete",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.car?.notes?.length) return;

          const note = message.data as CarNote;
          const noteIdx = appointment.car.notes.findIndex(n => n.id === note.id);
          if (noteIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            car: {
              ...appointment.car,
              notes: appointment.car.notes.slice(0, noteIdx).concat(appointment.car.notes.slice(noteIdx + 1))
            }
          });
        }
      },
      {
        model: "AcsesCommunication",
        filter: { appointment_id: Number(id) },
        action: "create",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment || appointment.acses_communication) return;

          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, acses_communication: message.data });
        }
      },
      {
        model: "AcsesCommunicationReceiver",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.acses_communication?.events?.length) return;

          const events = appointment.acses_communication.events;
          const receiver = message.data as AcsesCommunicationReceiver;

          const eventIdx = events.findIndex(e => e.id === receiver.acses_event_id);
          if (eventIdx < 0) return;
          const receivers = events[eventIdx].receivers;
          if (!receivers?.length) return;
          const receiverIdx = receivers.findIndex(r => r.id === receiver.id);
          if (receiverIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            acses_communication: {
              ...appointment.acses_communication,
              events: events.with(eventIdx, { ...events[eventIdx], receivers: receivers.with(receiverIdx, { ...receivers[receiverIdx], ...receiver }) })
            }
          });
        }
      },
      {
        model: "KioskCommunication",
        action: "append",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment) return;
          const communication = message.data as KioskCommunication;
          if (appointment.kiosk_communications?.some(k => k.id === communication.id)) return;
          queryClient.setQueryData(appointmentDetailsViewKey, { ...appointment, kiosk_communications: [...(appointment.kiosk_communications ?? []), communication] });
        }
      },
      {
        model: "KioskCommunication",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.kiosk_communications?.length) return;

          const communication = message.data as KioskCommunication;
          const commIdx = appointment.kiosk_communications.findIndex(k => k.id === communication.id);
          if (commIdx < 0) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            kiosk_communications: appointment.kiosk_communications.with(commIdx, { ...appointment.kiosk_communications[commIdx], ...communication })
          });
        }
      },
      {
        model: "DeskCommunicationEvent",
        action: "append",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.desk_communications?.length) return;

          const deskComEvent = message.data as DeskCommunicationEvent;
          const deskCommIdx = appointment.desk_communications.findIndex(dc => dc.id === deskComEvent.desk_communication_id);
          if (deskCommIdx < 0) return;

          const events = [...(appointment.desk_communications[deskCommIdx].events ?? []), deskComEvent];

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            desk_communications: appointment.desk_communications.with(deskCommIdx, { ...appointment.desk_communications[deskCommIdx], events } as DeskCommunication)
          });
        }
      },
      {
        model: "DeskCommunication",
        action: "create",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment) return;
          const deskCom = message.data as DeskCommunication;
          if (appointment.desk_communications?.some(dc => dc.id === deskCom.id)) return;
          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            desk_communications: [...(appointment.desk_communications ?? []), deskCom]
          });
        }
      },
      {
        model: "DeskCommunication",
        action: "update",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.desk_communications?.length) return;
          const deskCom = message.data as DeskCommunication;
          const deskComIdx = appointment.desk_communications.findIndex(dc => dc.id === deskCom.id);
          if (deskComIdx < 0) return;

          const updatedDeskCom = { ...appointment.desk_communications[deskComIdx], ...deskCom } as DeskCommunication;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            desk_communications: appointment.desk_communications.with(deskComIdx, updatedDeskCom)
          });
        }
      },
      {
        model: "CheckEvent",
        action: "append",
        filter: { appointment_id: Number(id) },
        callback: message => {
          const checkEvent = message.data as CheckEvent;
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment || appointment?.check_events?.some(ce => ce.id === checkEvent.id)) return;

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            check_events: appointment.check_events ? [...appointment.check_events, checkEvent] : [checkEvent]
          });
        }
      },
      {
        model: "KioskCommunicationEvent",
        action: "append",
        callback: message => {
          const appointment = queryClient.getQueryData<Appointment>(appointmentDetailsViewKey);
          if (!appointment?.kiosk_communications?.length) return;

          const event = message.data as KioskCommunicationEvent;
          const eventIdx = appointment.kiosk_communications.findIndex(kc => kc.id === event.kiosk_communication_id);
          if (eventIdx < 0) return;

          const events = [...(appointment.kiosk_communications[eventIdx].events ?? []), event];

          queryClient.setQueryData(appointmentDetailsViewKey, {
            ...appointment,
            kiosk_communications: appointment.kiosk_communications.with(eventIdx, { ...appointment.kiosk_communications[eventIdx], events } as KioskCommunication)
          });
        }
      }
    ];
  }, [queryClient, id]);

  const realTimeQuery = useRealTimeQuery({ queryKey: appointmentDetailsViewKey, queryFn: getAppointment, listeners });

  return {
    appointment: realTimeQuery.data,
    loading: realTimeQuery.isFetching,
    error: realTimeQuery.error
  };
};
