import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Modal } from "semantic-ui-react";

import "components/RDW/components/RDWDetails/RDWDetails.scss";
import { ITranslation } from "util/interfaces";

type RDWDetailsProps = {
  data: Record<string, string | number | undefined | null> | null;
  loading: boolean;
  onRefresh: () => void;
  onClose: () => void;
};

const RDWDetails = ({ data, onRefresh, onClose }: RDWDetailsProps) => {
  const t = useTranslation().t as ITranslation;

  if (!data) return null;

  const displayDate = (date: string): string => {
    if (date && date.length === 8) return `${date.substring(6)}-${date.substring(4, 6)}-${date.substring(0, 4)}`;

    return "";
  };

  const dataToDisplay = useMemo(() => {
    const prepareDataForDisplay = () => {
      const keyValues = Object.entries(data);

      return keyValues.map(([key, value]) => {
        let formattedKey = key.replace(/_/g, "");
        formattedKey = `${formattedKey[0].toLocaleUpperCase()}${formattedKey.slice(1)}`;

        let formatedValue = "";

        switch (key) {
          case "vervaldatum_apk":
          case "datum_tenaamstelling":
          case "datum_eerste_tenaamstelling_in_nederland":
          case "datum_eerste_toelating":
          case "vervaldatum_tachograaf":
          case "typegoedkeuringsnummer":
            formatedValue = displayDate(String(value));
            break;
          default:
            formatedValue = String(value);
            break;
        }

        return [formattedKey, formatedValue];
      });
    };

    return prepareDataForDisplay();
  }, [data]);

  return (
    <Modal size="small" open className="RDWDetails" dimmer="blurring" closeOnDimmerClick={false} onClose={onClose}>
      <Modal.Header className="RDWDetailsHeading">
        <div>RDW {t("v8_info").message || "Info"}</div>
        <div className="controls">
          <div className="icon-container">
            <Icon className="rotate" onClick={onRefresh} color="green" />
          </div>
          <div className="icon-container">
            <Icon className="x" onClick={onClose} color="grey" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Content className="RDWDetailsContent">
        {dataToDisplay?.map(([key, value]) => {
          const expiredDate = key === "Vervaldatumapk" && moment().isAfter(moment(data.vervaldatum_apk));
          return (
            <div key={key}>
              <span>{key}</span> <span style={{ color: expiredDate ? "red" : "black" }}>{value}</span>
            </div>
          );
        })}
      </Modal.Content>
    </Modal>
  );
};
export default RDWDetails;
