import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";

import { DATE_FORMATS, NavPortal } from "components";
import { Dealer } from "models";
import { LicenseItem, Licenses } from "modules/LocationLicenseOverview/types";
import { calculateTotalCosts, getNextMonthlyInvoicingDate } from "modules/LocationLicenseOverview/utils";
import { ITranslation } from "util/interfaces";

type LocationLicenseNavbarProps = {
  selectedDealer: Dealer | undefined;
  items: LicenseItem[];
  licensesData: Licenses | undefined;
};

export const LocationLicenseNavbar = ({ selectedDealer, items, licensesData }: LocationLicenseNavbarProps) => {
  const t = useTranslation().t as ITranslation;

  const { totalOneTimeCosts, totalMonthlyCosts } = useMemo(
    () => ({
      totalOneTimeCosts: calculateTotalCosts(items, licensesData?.oneTimeCategories, licensesData?.oneTimeStandalones),
      totalMonthlyCosts: calculateTotalCosts(items, licensesData?.monthlyCategories, licensesData?.monthlyStandalones)
    }),
    [licensesData, items]
  );

  return (
    <NavPortal>
      <div className="LocationLicenseOverview-Navbar">
        <div className="LocationLicenseOverview-Navbar-Item">
          <span>{t("v8_total_monthly").message || "Total monthly"}:</span>
          {`€ ${totalMonthlyCosts.toFixed(2)}`}
        </div>
        <div className="LocationLicenseOverview-Navbar-Item">
          <span>{t("v8_total_once").message || "Total once"}:</span>
          {`€ ${totalOneTimeCosts.toFixed(2)}`}
        </div>
        <div className="LocationLicenseOverview-Navbar-Item">
          <span>{t("v8_total_this_month").message || "Total this month"}:</span>
          {`€ ${(totalMonthlyCosts + totalOneTimeCosts).toFixed(2)}`}
        </div>
        <div className="LocationLicenseOverview-Navbar-Item">
          <span>{t("v8_next_monthly_bill").message || "Next monthly bill"}:</span>
          {getNextMonthlyInvoicingDate().format(DATE_FORMATS.dateMonthYear)}
        </div>
        {selectedDealer?.exact_account_id && selectedDealer?.is_exact_invoicing_enabled && (
          <div className="LocationLicenseOverview-Navbar-Item">
            <Popup
              wide
              size="tiny"
              position="bottom right"
              offset={[0, 1]}
              trigger={
                <span className="span-ellipsis">
                  {t("v8_dealer_invoices_activated").message ||
                    "Invoices on dealer level are activated. This dealers invoices can be found on dealer license overview >Invoice History"}
                </span>
              }
              content={
                <span>
                  {t("v8_dealer_invoices_activated").message ||
                    "Invoices on dealer level are activated. This dealers invoices can be found on dealer license overview >Invoice History"}
                </span>
              }
            />
          </div>
        )}
      </div>
    </NavPortal>
  );
};
