import { useQueryClient } from "@tanstack/react-query";

import { DealersLocationsDropdownValue } from "components";
import { LeadsNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { useDefaultNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/hooks/useGetNotSnoozedFilters";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";

export const useNotSnoozedFilterHandlers = () => {
  const queryClient = useQueryClient();
  const { defaultNotSnoozedFilters } = useDefaultNotSnoozedFilters();
  const filters = queryClient.getQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters);

  const handleResetFilters = () => {
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, defaultNotSnoozedFilters);
  };

  const handleSearchChange = (value: string) => {
    if (!filters) return;
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...filters, search_term: value });
  };

  const handleDealerLocationChange = ({ dealerIds, locationIds }: DealersLocationsDropdownValue) => {
    if (!filters) return;
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...filters, questions: null, dealer_ids: dealerIds, location_ids: locationIds });
  };

  const handleTagsChange = (value: number[]) => {
    if (!filters) return;
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...filters, tags: value });
  };

  const handleCustomerApprovedChange = () => {
    if (!filters) return;
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...filters, customer_approved: !filters?.customer_approved });
  };

  const handleInterventionsChange = () => {
    if (!filters) return;
    queryClient.setQueryData<LeadsNotSnoozedFilters>(LeadsQueryKeys.notSnoozedFilters, { ...filters, interventions: !filters?.interventions });
  };

  return { handleTagsChange, handleResetFilters, handleSearchChange, handleInterventionsChange, handleDealerLocationChange, handleCustomerApprovedChange };
};
