import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDealersLocations, usePinOptions, useUser } from "hooks";
import { Intervention, InterventionAttachment, PinModel, PinOptions } from "models";
import { PinType } from "modules/AppointmentDetails/components";
import { PinCategoryFormType } from "modules/AppointmentDetails/components/Interventions/components/PinCategory/components/PinCategoryForm";
import { useIntervention } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type PinCategoryProps = {
  data: Intervention;
  pinType: PinType;
};

export const usePinCategory = ({ data, pinType }: PinCategoryProps) => {
  const user = useUser();
  const { data: pinOptions } = usePinOptions();
  const { selectedDealer } = useDealersLocations();
  const t = useTranslation().t as ITranslation;
  const { addPinItem, addAttachmentToInterventionMutation } = useIntervention(data.appointment_id);

  const [showPrintOverview, setShowPrintOverview] = useState(false);
  const [attachments, setAttachments] = useState<InterventionAttachment[]>([]);

  const pinLogAttachments = (data?.pin_history || []).flatMap(history => history.note_attachments || []).map(attachment => ({ url: attachment }));
  const pinStatusData = pinOptions?.pin_statuses?.map((pinStatus: PinOptions) => ({ key: pinStatus.name, text: t(`${pinStatus.name}`).message, value: pinStatus.id }));

  const pinWarrantyData = pinOptions?.warranty_types
    ?.filter(pinWarranty => selectedDealer?.enabled_warranty_types?.includes(pinWarranty.id))
    ?.map((pinWarranty: PinOptions) => ({
      key: pinWarranty.name,
      text: t(`${pinWarranty.name}`).message,
      value: pinWarranty.id
    }));

  const openModal = () => setShowPrintOverview(true);
  const closeModal = () => setShowPrintOverview(false);

  const handleSubmit = async (values: PinCategoryFormType) => {
    const { claimNr, supportNr, refNr, note, keepParts, important, pinStatus, pinWarrantyType, orderStatus } = values;

    const pin: PinModel = {
      warranty_type_id: pinWarrantyType,
      support_nr: supportNr,
      pin_status_id: pinStatus,
      pin_type_id: pinType.value,
      order_status: orderStatus,
      ref_nr: refNr,
      claim_nr: claimNr,
      intervention_id: data?.id,
      appointment_id: data?.appointment_id,
      intervention: data,
      user_id: user ? user.id : 0,
      question_result: null,
      user,
      note_attachments: attachments.map(attach => attach.url),
      note,
      keep_parts: keepParts,
      visible_important_items: important
    };

    attachments.forEach(attach =>
      addAttachmentToInterventionMutation.mutate({ url: attach.url, name: attach.name, intervention_id: attach.intervention_id!, type: attach.type! })
    );
    await addPinItem.mutateAsync(pin);
  };

  return {
    openModal,
    closeModal,
    handleSubmit,
    setAttachments,
    pinOptions,
    attachments,
    pinStatusData,
    pinWarrantyData,
    showPrintOverview,
    pinLogAttachments
  };
};
