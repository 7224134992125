import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Segment, SidebarPushable, SidebarPusher } from "semantic-ui-react";

import { MenuItem, MenuSection, MenuView, NavBar } from "components";
import { SessionManager } from "components/SessionManager";
import { NavActionsProvider, useDealersLocations } from "hooks";
import "layouts/PrivateRouteLayout/PrivateRouteLayout.scss";
import LoginModal from "modules/Auth/components/LoginModal";
import { PATHS } from "router/paths";
import { ITranslation } from "util/interfaces";

type PrivateRouteLayoutProps = {
  children: React.ReactNode;
};

export const PrivateRouteLayout = ({ children }: PrivateRouteLayoutProps) => {
  const [showSidebarMenu, setSidebarMenu] = useState(false);
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;

  const hideSidebar = () => {
    setSidebarMenu(false);
  };

  return (
    <div className="main-container">
      <SessionManager />
      <LoginModal />
      <SidebarPushable as={Segment} className="main-container-pushable">
        <MenuView isSideBarOpen={showSidebarMenu} hideSidebar={hideSidebar}>
          <MenuSection title={t("v8_dashboards").message || "Dashboards"}>
            <MenuItem
              title={t("v8_appointment_list").message || "Appointment List"}
              icon="list"
              link={PATHS.APPOINTMENTS}
              resource="appointments-page"
              activity="browse"
            />
            <MenuItem
              title={t("v8_dayplanner").message || "Dayplanner"}
              icon="square kanban regular"
              link={PATHS.DAYPLANNER}
              resource="dayplanner-page"
              activity="browse"
              condition={selectedLocation?.is_dayplanner_enabled}
            />
            <MenuItem title={t("v8_leads").message || "Leads"} icon="clock" link={PATHS.LEADS_DASHBOARD} resource="leads-dashboard-page" activity="browse" />
          </MenuSection>

          <MenuSection title={t("v8_reports").message || "Reports"}>
            <MenuItem
              title={t("v8_dealer_reports").message || "Dealer Reports"}
              icon="chart column"
              link={PATHS.DEALER_REPORTS}
              resource="reports-page"
              activity="browse"
            />
            <MenuItem
              title={t("v8_location_reports").message || "Location Reports"}
              icon="chart column"
              link={PATHS.LOCATION_REPORTS}
              resource="locations-reports-page"
              activity="browse"
            />
          </MenuSection>

          <MenuSection title={t("v8_resources").message || "Resources"}>
            <MenuItem
              title={t("v8_dealer_documentation").message || "Dealer Documentation"}
              icon="file"
              link={PATHS.DEALER_DOCUMENTATION}
              resource="dealer-documentation-page"
              activity="browse"
            />
          </MenuSection>

          <MenuSection title={t("v8_communications").message || "Communications"}>
            <MenuItem
              title={t("v8_settings").message || "Settings"}
              icon="closed captioning outline"
              link={PATHS.CUSTOMER_COM}
              activity="browse"
              resource="customer-comm-settings-page"
            />
          </MenuSection>
          <MenuSection title={t("v8_exact").message || "Exact"}>
            <MenuItem
              title={t("v8_exact_failures").message || "Exact Failures"}
              icon="file invoice dollar"
              link={PATHS.EXACT_FAILURES}
              resource="exact-failures-page"
              activity="browse"
            />
            <MenuItem
              title={t("v8_location_licenses").message || "Location Licenses"}
              icon="id card"
              link={PATHS.LOCATION_LICENSE_OVERVIEW}
              resource="exact-license-page"
              activity="browse"
            />
            <MenuItem
              title={t("v8_dealer_licenses").message || "Dealer Licenses"}
              icon="file certificate"
              link={PATHS.DEALER_LICENSE_OVERVIEW}
              resource="dealer-license-page"
              activity="browse"
            />
          </MenuSection>

          <MenuSection title={t("v8_configuration").message || "Configuration"}>
            <MenuItem title={t("v8_notes_menu").message || "Notes"} icon="notes sticky" link={PATHS.NOTES_MENU} resource="note-suggestions" activity="list" />
          </MenuSection>
        </MenuView>
        <SidebarPusher dimmed={showSidebarMenu} className="main-container-pusher-dimmed">
          <Segment basic className="main-container-segment">
            <NavActionsProvider>
              <NavBar openSidebar={() => setSidebarMenu(true)} />
              <div id="containerId" className="content-container">
                {children}
              </div>
            </NavActionsProvider>
          </Segment>
        </SidebarPusher>
      </SidebarPushable>
    </div>
  );
};
