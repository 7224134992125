import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Icon, Modal } from "semantic-ui-react";

import { CUSTOM_REASON, CustomReasons } from "components";
import "components/CustomConfirm/CustomConfirm.scss";
import { ITranslation } from "util/interfaces";

export enum CUSTOM_CONFIRM_TYPES {
  Danger = 1,
  Warning
}

type ConfirmReasonType = {
  id: number;
  name: string;
};

type CustomConfirmProps = {
  type: number;
  isOpen: boolean;
  handleConfirm?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  confirmMsg: string;
  confirmNote?: string;
  confirmMandatoryNote?: string;
  declineMandatoryNote?: string;
  note?: string;
  customClass?: string;
  onChangeNote?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleChangeCustomReason?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  confirmReasons?: ConfirmReasonType[];
  allowCustomReason?: boolean;
  isLoading?: boolean;
  error?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  placeholderText?: string;
  customTitle?: string;
  handleReasonChange?: (value: number) => void;
  handleDecline?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const getCustomConfirmStyle = (type: number) => {
  switch (type) {
    case CUSTOM_CONFIRM_TYPES.Danger:
      return "danger";
    case CUSTOM_CONFIRM_TYPES.Warning:
      return "warning";

    default:
      return "info";
  }
};

export const CustomConfirm = ({
  type,
  isOpen,
  handleConfirm,
  handleCancel,
  confirmMsg,
  confirmReasons,
  allowCustomReason,
  confirmNote,
  confirmMandatoryNote,
  declineMandatoryNote,
  handleDecline,
  note,
  customClass,
  onChangeNote,
  handleReasonChange,
  handleChangeCustomReason,
  isLoading,
  error = "",
  cancelButtonText,
  confirmButtonText,
  placeholderText,
  customTitle
}: CustomConfirmProps) => {
  const t = useTranslation().t as ITranslation;
  const typeClassName = getCustomConfirmStyle(type);
  const [showCustomReason, setShowCustomReason] = useState(false);
  const getTitle = () => (type === CUSTOM_CONFIRM_TYPES.Danger ? t("v8_confirmation_required").message || "Confirmation required" : t("v8_warning").message || "Warning");

  const handleChange = (value: number) => {
    if (value == CUSTOM_REASON && allowCustomReason) {
      setShowCustomReason(true);
    } else {
      setShowCustomReason(false);
    }
    handleReasonChange && handleReasonChange(value);
  };

  return (
    <Modal size="tiny" open={isOpen} className={`CustomConfirm ${customClass}`} onClose={handleCancel}>
      <Modal.Header className={typeClassName}>{customTitle || getTitle()}</Modal.Header>
      <Modal.Content className={typeClassName}>
        <div className="CustomConfirm-section">
          {type === CUSTOM_CONFIRM_TYPES.Danger && <Icon className="exclamation triangle red" size="big" />}
          <div className="CustomConfirm-inner-section">
            <div className="CustomConfirm-inner-section-heading">{confirmMsg}</div>

            <Form>
              {confirmReasons && (
                <CustomReasons placeholderText={placeholderText} confirmReasons={confirmReasons} handleOnChange={handleChange} allowCustomReason={allowCustomReason} />
              )}
              {showCustomReason && (
                <Form.Field className="mt-15">
                  <Form.TextArea name="customReason" placeholder={t("v8_enter_a_reason").message || "Enter a reason"} onChange={handleChangeCustomReason} />
                </Form.Field>
              )}
              {(confirmNote || confirmMandatoryNote || declineMandatoryNote) && (
                <Form.Field>
                  <label>{confirmNote || confirmMandatoryNote || declineMandatoryNote}</label>
                  <Form.TextArea value={note} name="note" onChange={onChangeNote} />
                </Form.Field>
              )}
            </Form>

            {!!error && <div className="error">{error}</div>}
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={handleCancel} disabled={isLoading} basic>
          {cancelButtonText || t("v8_cancel").message || "Cancel"}
          <Icon className="xmark" />
        </Button>

        {handleDecline ? (
          <Button onClick={handleDecline} disabled={isLoading} loading={isLoading} className={typeClassName}>
            {t("v8_decline").message || "Decline"}
            <Icon className="xmark" />
          </Button>
        ) : (
          handleConfirm && (
            <Button onClick={handleConfirm} disabled={isLoading} loading={isLoading} className={typeClassName}>
              {confirmButtonText || t("v8_confirm").message || "Confirm"}
              <Icon className="check" />
            </Button>
          )
        )}
      </Modal.Actions>
    </Modal>
  );
};
