import { Grid, GridColumn, Icon } from "semantic-ui-react";

import { ANSWER_STATUS_SELECTOR_TYPE, AnswerStatusSelector, Can, useCan } from "components";
import { useDealersLocations } from "hooks";
import { Intervention } from "models";
import { getPriceInVAT } from "util/common";

type CustomerCommunicationInterventionItemProps = {
  intervention: Intervention;
  toggleVisibleInPDF: (item: Intervention) => void;
  showVat: boolean;
  toggleCustomerOk?: (item: Intervention) => void;
  canToggleCustomerOk: boolean;
};

export const CustomerCommunicationInterventionItem = ({
  intervention,
  toggleVisibleInPDF,
  toggleCustomerOk,
  showVat,
  canToggleCustomerOk
}: CustomerCommunicationInterventionItemProps) => {
  const { selectedLocation: location } = useDealersLocations();
  const canUpdateInterventions = useCan("update", "appointments");

  return (
    <div className="Intervention" key={intervention.id}>
      <Grid>
        <GridColumn width={10}>
          <div className="title-container">
            {intervention?.question_result_status ? (
              <div className="question-status-container">
                <AnswerStatusSelector
                  data={{ ...intervention, status: intervention.question_result_status as number | null } as Intervention}
                  type={ANSWER_STATUS_SELECTOR_TYPE.intervention}
                  disabled
                />
              </div>
            ) : null}
            <div className="title-container-button">
              <Icon className="desktop" size="tiny" />
              <p>{intervention.title}</p>
            </div>
          </div>
        </GridColumn>
        <GridColumn width={3}>
          {location?.dms_price_enabled && (
            <div className="column-container float-right">
              <p>{`€ ${showVat ? getPriceInVAT(intervention.price, location).toFixed(2) : intervention.price.toFixed(2)}`}</p>
            </div>
          )}
        </GridColumn>
        <Can I="update" the="interventions">
          <GridColumn width={3}>
            <div className="column-container float-right">
              <div className="status-container">
                <div>
                  <Icon
                    className={`eye ${intervention.visible_to_customer ? "green" : "slash red"} ${canUpdateInterventions ? "pointer" : ""}`}
                    size="small"
                    onClick={() => toggleVisibleInPDF(intervention)}
                  />
                </div>
                {toggleCustomerOk && (
                  <div>
                    <Icon
                      className={`${intervention.customer_ok ? "check circle green" : "remove circle red"}`}
                      size="large"
                      onClick={() => toggleCustomerOk(intervention)}
                      disabled={!canUpdateInterventions || !canToggleCustomerOk}
                    />
                  </div>
                )}
              </div>
            </div>
          </GridColumn>
        </Can>
      </Grid>
    </div>
  );
};
