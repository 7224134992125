import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { STATUS_IDENTIFIER } from "models";
import { queryKeys } from "util/keyFactory";

export type StatusData = {
  icon: string;
  value: STATUS_IDENTIFIER;
  isActive: boolean;
  id: STATUS_IDENTIFIER;
};

export const statusData: StatusData[] = [
  { icon: "calendar check", isActive: true, value: STATUS_IDENTIFIER.CheckInCar, id: STATUS_IDENTIFIER.CheckInCar },
  { icon: "wrench", isActive: true, value: STATUS_IDENTIFIER.CarCheckStatus, id: STATUS_IDENTIFIER.CarCheckStatus },
  { icon: "square a", isActive: true, value: STATUS_IDENTIFIER.AannameOKStatus, id: STATUS_IDENTIFIER.AannameOKStatus },
  { icon: "dollar", isActive: true, value: STATUS_IDENTIFIER.CalculatingStatus, id: STATUS_IDENTIFIER.CalculatingStatus },
  { icon: "tag", isActive: true, value: STATUS_IDENTIFIER.PricingOKStatus, id: STATUS_IDENTIFIER.PricingOKStatus },
  { icon: "triangle exclamation", isActive: true, value: STATUS_IDENTIFIER.DiagnoseStatus, id: STATUS_IDENTIFIER.DiagnoseStatus },
  { icon: "user check", isActive: true, value: STATUS_IDENTIFIER.CustomerAnsweredStatus, id: STATUS_IDENTIFIER.CustomerAnsweredStatus },
  { icon: "face", isActive: true, value: STATUS_IDENTIFIER.CustomerOKStatus, id: STATUS_IDENTIFIER.CustomerOKStatus },
  { icon: "check double", isActive: true, value: STATUS_IDENTIFIER.CarReadyStatus, id: STATUS_IDENTIFIER.CarReadyStatus },
  { icon: "shield check", isActive: true, value: STATUS_IDENTIFIER.QualityCheckStatus, id: STATUS_IDENTIFIER.QualityCheckStatus },
  { icon: "right from bracket outOfShop", isActive: true, value: STATUS_IDENTIFIER.CarOutOfShop, id: STATUS_IDENTIFIER.CarOutOfShop },
  { icon: "car garage", isActive: true, value: STATUS_IDENTIFIER.CarInShop, id: STATUS_IDENTIFIER.CarInShop },
  { icon: "car mirrors", isActive: true, value: STATUS_IDENTIFIER.NewCarStatus, id: STATUS_IDENTIFIER.NewCarStatus },
  { icon: "bo", isActive: true, value: STATUS_IDENTIFIER.BackOrderStatus, id: STATUS_IDENTIFIER.BackOrderStatus },
  { icon: "circle xmark", isActive: true, value: STATUS_IDENTIFIER.CanceledStatus, id: STATUS_IDENTIFIER.CanceledStatus }
];

export const useGetAppointmentsSelectedIdentifiers = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [queryKeys.appointments.selectedIdentifiers],
    queryFn: (): StatusData[] => queryClient.getQueryData([queryKeys.appointments.selectedIdentifiers]) || [...statusData]
  });

  return query.data || [];
};

export const useUpdateAppointmentsSelectedIdentifiers = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (newStatusIdentifiers: StatusData[]): Promise<StatusData[]> => Promise.resolve(newStatusIdentifiers),
    onMutate: (newStatusIdentifiers: StatusData[]) => {
      queryClient.setQueryData([queryKeys.appointments.selectedIdentifiers], [...newStatusIdentifiers]);
    }
  });

  return mutation.mutate;
};

export const useResetAppointmentsSelectedIdentifiers = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.setQueryData(
      [queryKeys.appointments.selectedIdentifiers],
      statusData.map(sd => ({ ...sd, isActive: true }))
    );
  };
};
