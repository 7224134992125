import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, CheckList, CustomConfirm, DetailsCards, TabData } from "components";
import { Appointment, CHECKLIST_TYPE, Check } from "models";
import { OrderedChecklist } from "modules/AppointmentDetails";
import { ChecklistPDF } from "modules/AppointmentDetails/components";
import { useChecklist } from "modules/AppointmentDetails/hooks";
import { useConnectedInterventionId } from "modules/AppointmentDetails/hooks/index";
import { ITranslation } from "util/interfaces";

type ExtraPartsProps = {
  isExpanded: boolean;
  checks: OrderedChecklist[];
  appointment: Appointment;
  tabData: TabData;
  latestChecklist: number;
};

export const ExtraParts = ({ isExpanded, appointment, checks, tabData, latestChecklist }: ExtraPartsProps) => {
  const { deleteCheckList, canDeleteCheck } = useChecklist(appointment.id);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const t = useTranslation().t as ITranslation;
  const { connectedInterventionId } = useConnectedInterventionId();

  const extraPartData = useMemo(
    () => checks?.filter((item: Check) => item.checklist.checklist_type === CHECKLIST_TYPE.ExtraParts).sort((a, b) => b.id - a.id)?.[0],
    [checks]
  );
  const isLatestCheckList = latestChecklist === extraPartData?.id;

  const extraPartTitle = (
    <div className="StandardList-title">
      <span className="ellipsis">{`#${extraPartData?.order} ${t("v8_extra_parts").message || "Extra Parts"} - `}</span>

      {!extraPartData?.checklist.template?.hide_timestamp && extraPartData?.mechanic && (
        <span className="mechanic-info">
          <span>
            {extraPartData.mechanic.first_name} {extraPartData.mechanic.last_name}
          </span>
          <span className="separator"> - </span>
          <span className="first-date">{`${moment(extraPartData?.created_on).subtract(extraPartData?.duration, "seconds").format("HH:mm DD-MM-YY")}`}</span>
          <Icon className="arrow right" />
          <span className="second-date">{`${moment(extraPartData?.created_on).format("HH:mm DD-MM-YY")}`}</span>
        </span>
      )}
    </div>
  );

  const toggleShowAll = () => setShowAll(prev => !prev);

  if (connectedInterventionId) return null;
  return (
    <DetailsCards
      id={tabData.id}
      title={extraPartTitle}
      icon="briefcase"
      isChecklistTitle={true}
      isExpanded={isExpanded}
      backgroundColor={extraPartData?.checklist.template?.primary_color || ""}
      borderColor={extraPartData?.checklist.template?.text_color || ""}
      textColor={extraPartData?.checklist.template?.text_color || ""}
      rightContent={
        <div className="full end auto-width">
          {isLatestCheckList && canDeleteCheck && (
            <Button className="-appointment-status icon" size="small" onClick={() => setConfirmDeleteIsOpen(true)}>
              <Icon className="trash" color="green" />
            </Button>
          )}

          {extraPartData?.checklist?.template?.total_pdf_enabled && (
            <ChecklistPDF appointment={appointment} isPreviewable={extraPartData.checklist.template.is_previewable} checkId={extraPartData.id} />
          )}

          <Button className="-appointment-status icon" size="small" onClick={toggleShowAll}>
            <Icon className="bars" color={showAll ? "green" : "grey"} />
          </Button>

          <CustomConfirm
            isOpen={confirmDeleteIsOpen}
            handleCancel={() => setConfirmDeleteIsOpen(false)}
            handleConfirm={() => deleteCheckList.mutate(latestChecklist)}
            confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action?"}
            isLoading={deleteCheckList.isPending}
            type={CUSTOM_CONFIRM_TYPES.Warning}
          />
        </div>
      }
    >
      {extraPartData && (
        <CheckList
          data={extraPartData.question_items}
          template={extraPartData.checklist.template}
          showAll={showAll}
          showDropdownActions={false}
          appointment={appointment}
        />
      )}
    </DetailsCards>
  );
};
