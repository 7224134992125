import _snakeCase from "lodash/snakeCase";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SNOOZE_DEPARTMENT, SNOOZE_STATUS, SNOOZE_TYPE, useGetSnoozeTypeData } from "components";
import { useUser } from "hooks";
import { QUESTION_STATUSES } from "models";
import { ITranslation, SelectOption } from "util/interfaces";

export interface SelectOptionWithIcon<T> extends SelectOption<T> {
  icon?: string | null;
  iconColor?: string;
  groupTitle?: string;
}

export enum LEASE_OPTIONS {
  All = "all",
  Lease = "lease",
  NoLease = "no_lease"
}

export interface LeaseCompanyType extends SelectOption<string> {
  filterValue: boolean | null;
}

export type PossibleQuestionStatusOptions = QUESTION_STATUSES.OKWithRemarks | QUESTION_STATUSES.Advice | QUESTION_STATUSES.Necessary;
export type QuestionStatusOptions = [QUESTION_STATUSES.OKWithRemarks, QUESTION_STATUSES.Advice, QUESTION_STATUSES.Necessary];

export interface QuestionStatusType extends SelectOptionWithIcon<string | number> {
  filterValue: PossibleQuestionStatusOptions | QuestionStatusOptions | null;
}

export interface CustomerOkUserType extends SelectOption<string | number> {
  filterValue: boolean;
}

export const useGetSnoozeStatusOptions = () => {
  const t = useTranslation().t as ITranslation;
  const selectAllOption = { key: "all", value: "all", text: t("v8_all").message || "All" };
  const deleteOption = { key: SNOOZE_STATUS.Deleted, value: SNOOZE_STATUS.Deleted, text: t("v8_deleted").message || "Deleted" };

  const snoozeStatusOptions: SelectOption<SNOOZE_STATUS | string>[] = useMemo(
    () => {
      const options: SelectOption<SNOOZE_STATUS>[] = Object.values(SNOOZE_STATUS).reduce((acc, status) => {
        if (typeof status === "number" && status !== SNOOZE_STATUS.Deleted)
          acc.push({
            key: status as SNOOZE_STATUS,
            value: status as SNOOZE_STATUS,
            text: t(`v8_${_snakeCase(SNOOZE_STATUS[status])}`).message || `${SNOOZE_STATUS[status]}`
          });

        return acc;
      }, [] as SelectOption<SNOOZE_STATUS>[]);

      return [selectAllOption, ...options, deleteOption];
    },
    [] as SelectOption<SNOOZE_STATUS | string>[]
  );

  return { snoozeStatusOptions };
};

export const useGetSnoozeTypeOptions = () => {
  const t = useTranslation().t as ITranslation;
  const { dealerSnoozeTypeData, allSnoozeTypeData } = useGetSnoozeTypeData();
  const selectAllOption = { key: "all", value: "all", text: t("v8_all").message || "All" };

  const snoozeTypeOptions: SelectOption<SNOOZE_TYPE | string>[] = [selectAllOption, ...dealerSnoozeTypeData];
  return { dealerSnoozeTypeOptions: snoozeTypeOptions, allSnoozeTypeOptions: allSnoozeTypeData };
};

export const useGetDepartmentOptions = () => {
  const t = useTranslation().t as ITranslation;

  const departmentOptions = useMemo(() => {
    const options: SelectOption<number>[] = Object.values(SNOOZE_DEPARTMENT).reduce((acc, status) => {
      if (typeof status === "number")
        acc.push({
          key: status as SNOOZE_DEPARTMENT,
          value: status as SNOOZE_DEPARTMENT,
          text: t(`v8_${_snakeCase(SNOOZE_DEPARTMENT[status])}`).message || `${SNOOZE_DEPARTMENT[status]}`
        });

      return acc;
    }, [] as SelectOption<number>[]);

    return options;
  }, []);

  return { departmentOptions };
};

export const useGetLeaseCompanyOptions = () => {
  const t = useTranslation().t as ITranslation;

  const leaseCompanyOptions = useMemo(() => {
    const options: LeaseCompanyType[] = [
      { key: LEASE_OPTIONS.All, value: LEASE_OPTIONS.All, text: t("v8_all").message || "All", filterValue: null },
      { key: LEASE_OPTIONS.Lease, value: LEASE_OPTIONS.Lease, text: t("v8_lease").message || "Lease", filterValue: true },
      { key: LEASE_OPTIONS.NoLease, value: LEASE_OPTIONS.NoLease, text: t("v8_no_lease").message || "No Lease", filterValue: false }
    ];

    return options;
  }, []);

  return { leaseCompanyOptions };
};

export const useGetQuestionResultStatusOptions = () => {
  const t = useTranslation().t as ITranslation;

  const questionResultStatusOptions = useMemo(() => {
    const options: QuestionStatusType[] = [
      { key: "all", value: "all", text: t("v8_all").message || "All", icon: null, filterValue: null, iconColor: "" },
      {
        key: QUESTION_STATUSES.OKWithRemarks,
        value: QUESTION_STATUSES.OKWithRemarks,
        text: t("v8_ok").message || "Ok",
        icon: "file",
        iconColor: "#21BA45",
        filterValue: QUESTION_STATUSES.OKWithRemarks
      },
      {
        key: QUESTION_STATUSES.Advice,
        value: QUESTION_STATUSES.Advice,
        text: t("v8_advice").message || "Advice",
        icon: "triangle exclamation",
        iconColor: "#FFBA39",
        filterValue: QUESTION_STATUSES.Advice
      },
      {
        key: QUESTION_STATUSES.Necessary,
        value: QUESTION_STATUSES.Necessary,
        text: t("v8_necessary").message || "Necessary",
        icon: "circle exclamation",
        iconColor: "#FF0202",
        filterValue: QUESTION_STATUSES.Necessary
      }
    ];

    return options;
  }, []);

  return { questionResultStatusOptions };
};

export const useGetCustomerOkUserOptions = () => {
  const user = useUser();
  const t = useTranslation().t as ITranslation;

  const customerOkUserOptions = useMemo(() => {
    const options: CustomerOkUserType[] = [
      { key: "all", value: "all", text: t("v8_all").message || "All", filterValue: false },
      { key: user?.id || 0, value: user?.id || 0, text: `${user?.first_name} ${user?.last_name}`, filterValue: true }
    ];

    return options;
  }, []);

  return { customerOkUserOptions };
};
