import _startCase from "lodash/startCase";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, Icon } from "semantic-ui-react";

import { DATE_FORMATS, InterventionElementWithTyre, SNOOZE_DEPARTMENT, SNOOZE_STATUS, SNOOZE_TYPE, useCan } from "components";
import "components/CheckList/CheckList.scss";
import { useDealersLocations, useTyreName } from "hooks";
import { Appointment, ChecklistTemplate, INTERVENTION_ELEMENT_TYPE, QuestionResult, QuestionResultImage, SnoozeItem, TYRE_SEASONS } from "models";
import { GenericAttachmentData } from "modules/AppointmentDetails/components/Interventions/components";
import { tyrePosition } from "modules/AppointmentDetails/components/Tyre";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

export enum MODAL_TYPE {
  Intervention = "intervention",
  Snooze = "snooze",
  EditSnooze = "editsnooze"
}

export interface ExtendedInterventionData extends InterventionData {
  modalType: MODAL_TYPE;
}

export type QuestionResultData = {
  modalType: MODAL_TYPE;
  title: string;
  description: string;
  solution: string;
  question_result_id: number;
  id?: number;
  images?: QuestionResultImage[];
  appointment_id?: number;
  snoozed?: boolean;
  snooze_history?: SnoozeItem[];
  snooze_type_id?: SNOOZE_TYPE;
  snooze_status_id?: SNOOZE_STATUS;
  snooze_department_id?: SNOOZE_DEPARTMENT;
  snooze_date?: Date;
  note?: string;
  attachments?: GenericAttachmentData[];
  checkin_remark_id?: number;
  diagnose_overview_remark_id?: number | null;
  snooze_id?: number | null;
};

export type ActionModalSelectorData = ExtendedInterventionData | QuestionResultData;

type ActionModalSelectorProps = {
  onSelect: (data: ActionModalSelectorData) => void;
  data: Partial<QuestionResult>;
  attachments?: GenericAttachmentData[];
  template?: ChecklistTemplate | null;
  editSnooze?: boolean;
  onlyIntervention?: boolean;
  isConvertToInterventionButtonDisabled?: boolean;
  appointment: Appointment;
  snoozeId?: number;
};

interface ActionSelectorOption {
  icon: JSX.Element;
  text: string;
  value: string;
  disabled?: boolean;
}

export const ActionModalSelector = ({
  data,
  attachments = [],
  template,
  onSelect,
  editSnooze,
  onlyIntervention,
  isConvertToInterventionButtonDisabled = false,
  appointment,
  snoozeId
}: ActionModalSelectorProps) => {
  const { selectedLocation } = useDealersLocations();
  const canAddSnooze = useCan("add", "snooze");
  const canAddIntervention = useCan("add", "interventions");
  const t = useTranslation().t as ITranslation;
  const { getTyreName } = useTyreName();

  const snoozeOption = editSnooze
    ? { icon: <Icon className="pen" />, text: t("v8_edit").message || "Edit", value: MODAL_TYPE.EditSnooze }
    : { icon: <Icon className="clock" />, text: t("v8_snooze").message || "Snooze", value: MODAL_TYPE.Snooze };

  const initOptions: ActionSelectorOption[] = !canAddIntervention
    ? []
    : [
        {
          icon: <Icon className="screwdriver wrench" />,
          text: t("v8_convert_to_intervention").message || "Convert to Intervention",
          value: MODAL_TYPE.Intervention,
          disabled: isConvertToInterventionButtonDisabled
        }
      ];

  const [options, setOptions] = useState(initOptions);

  const readOptions = () => {
    if (!canAddSnooze) return;

    const optionsList = [...initOptions];
    if (
      !onlyIntervention &&
      selectedLocation?.schedule_enabled &&
      (data.question_options?.find(option => option.name === "snooze_enabled")?.value || !data.question_options)
    ) {
      if (!template || template?.snooze_enabled) {
        optionsList.push(snoozeOption);
      }
    }
    setOptions(optionsList);
  };

  useEffect(() => {
    readOptions();
  }, [selectedLocation, data]);

  const seasonOptions = [
    { value: TYRE_SEASONS.AllSeason, text: t("v8_all_season").message || "All season" },
    { value: TYRE_SEASONS.Summer, text: t("v8_summer").message || "Summer" },
    { value: TYRE_SEASONS.Winter, text: t("v8_winter").message || "Winter" }
  ];

  const getTyreTitle = () => {
    if (!data.tyre_position) return "";

    let tyreTitle = t("v8_on_truck").message || "On Truck";
    if (tyrePosition.isInCar(data.tyre_position)) tyreTitle = t("v8_in_storage").message || "In Storage";
    else if (tyrePosition.isOnCar(data.tyre_position)) tyreTitle = t("v8_on_car").message || "On Car";

    return `${tyreTitle} - ${getTyreName(data.tyre_position)}`;
  };

  const handleSelect = (value: string) => {
    const isQuestionResultHistoryItem = appointment.id !== data.appointment_id;
    if (value === MODAL_TYPE.Intervention) {
      const elements: InterventionElementWithTyre[] =
        data?.tyre_replacements?.map(el => {
          const tyre = el.tyre;
          const tyreSeason = seasonOptions.find(item => item.value === tyre?.season)?.text || "";

          return {
            created_on: tyre?.created_on ?? "",
            updated_on: tyre?.updated_on ?? "",
            id: tyre?.id,
            dms_nr: `claire_${moment().utc().format(DATE_FORMATS.ISOFormat)}`,
            description: [tyreSeason, tyre?.description, tyre?.manufacturer].filter(Boolean).join(" - "),
            price: el.price,
            quantity: 1,
            private_note: data.note,
            element_type: INTERVENTION_ELEMENT_TYPE.Part,
            tyre: tyre || null,
            is_tyre_replacement: true,
            unit: el.price,
            customer_approved: data.customer_approved
          };
        }) || [];

      onSelect({
        modalType: value,
        title: getTyreTitle() || data.title || "",
        description: data.mechanic_notes || data.raw || "",
        solution: data.solution || "",
        customer_ok: false,
        appointment_id: appointment.id || data.appointment_id,
        question_result_id: !snoozeId ? data.id : null,
        snooze_id: isQuestionResultHistoryItem && snoozeId ? snoozeId : null,
        attachments,
        price: data.tyre_replacements?.length === 1 ? data.tyre_replacements[0].price : data.price || 0,
        checkin_remark_id: data.checkin_remark_id,
        checkin_result_id: data.checkin_result_id,
        diagnose_overview_remark_id: data.diagnose_overview_remark_id,
        elements
      });
    } else if (value === MODAL_TYPE.Snooze || value === MODAL_TYPE.EditSnooze) {
      onSelect({
        modalType: value,
        title: data.title || "",
        description: data.raw || "",
        solution: data.solution || "",
        question_result_id: data.id || 0,
        appointment_id: data.appointment_id,
        snooze_history: data.snooze_history,
        attachments: value === MODAL_TYPE.Snooze ? attachments : []
      });
    }
  };
  if (!options.length) return null;

  return (
    <Dropdown className="action-dropdown" floating icon={null} trigger={<Icon className="plus grey" />}>
      <DropdownMenu className="dropdown-menu">
        {options.map(option => (
          <DropdownItem key={option.value} {...option} onClick={() => handleSelect(String(option.value))} />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
