import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { QuestionResultData } from "components";
import SnoozeModal from "components/SnoozeModal";
import { useDealersLocations } from "hooks";
import { LeadsNotSnoozed, SnoozeItem } from "models";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { NotSnoozedLeadsTable } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components";
import { NotSnoozedLeadsFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/components/NotSnoozedFilters";
import { useGetNotSnoozedFilters } from "modules/LeadsDashboard/Leads/NotSnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetTagsQuery, useLeadsNotSnoozedQuery } from "modules/LeadsDashboard/service";
import { groupWOResults } from "modules/LeadsDashboard/util";

export const NotSnoozedLeads = () => {
  const tags = useGetTagsQuery();
  const queryClient = useQueryClient();
  const { locations } = useDealersLocations();
  const { notSnoozedFilters } = useGetNotSnoozedFilters();
  const { refetch } = useLeadsNotSnoozedQuery(notSnoozedFilters);
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);

  const notSnoozedLeads = queryClient.getQueryData<LeadsNotSnoozed>([LeadsQueryKeys.listNotSnoozed]);
  const groupedNotSnoozedLeadsData = groupWOResults(notSnoozedLeads?.items || []);

  useEffect(() => {
    const hasDealerLocationSelected = notSnoozedFilters.location_ids?.length > 0 || notSnoozedFilters.dealer_ids?.length > 0;
    const hasDateRangeSelected = notSnoozedFilters.date_from && notSnoozedFilters.date_to;

    if (hasDealerLocationSelected && hasDateRangeSelected) refetch();
  }, [notSnoozedFilters]);

  const onSnoozeSave = (data: Partial<SnoozeItem>) => {
    const questionItem = groupedNotSnoozedLeadsData.find(item => item.question_result_id === data.question_result_id);
    if (!questionItem) return;

    const updatedItems = groupedNotSnoozedLeadsData.map(item => {
      if (item.question_result_id !== data.question_result_id) return item;

      return { ...item, ...data, question_result: { ...item.question_result, snooze_history: [...(item.question_result?.snooze_history ?? []), { ...data }] } };
    });

    queryClient.setQueryData([LeadsQueryKeys.listNotSnoozed], { ...notSnoozedLeads, items: updatedItems, nb_items: updatedItems.length });
  };

  const onSnoozeDelete = (questionResultId: number) => {
    const updatedItems = groupedNotSnoozedLeadsData.filter(element => element.question_result_id !== questionResultId);

    if (updatedItems.length !== groupedNotSnoozedLeadsData.length)
      queryClient.setQueryData([LeadsQueryKeys.listNotSnoozed], { ...groupedNotSnoozedLeadsData, items: updatedItems });
  };

  return (
    <>
      <NotSnoozedLeadsFilters filters={notSnoozedFilters} tagsOptions={tags} />
      <NotSnoozedLeadsTable
        locations={locations}
        dateFrom={notSnoozedFilters.date_from}
        dateTo={notSnoozedFilters.date_to}
        setModalData={setModalData}
        notSnoozedData={groupedNotSnoozedLeadsData}
        nbPages={notSnoozedLeads?.nb_pages || 0}
        nbItems={notSnoozedLeads?.nb_items || 0}
      />
      <SnoozeModal
        size="large"
        open={modalData !== null}
        onClose={() => setModalData(null)}
        closeOnDimmerClick
        data={modalData}
        onSave={(data: Partial<SnoozeItem>) => onSnoozeSave(data)}
        onDelete={(questionResultId: number) => onSnoozeDelete(questionResultId)}
      />
    </>
  );
};
