import { QueryFunctionContext, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { useDealersLocations } from "hooks";
import { LocationLicenseOverviewKeys } from "modules/LocationLicenseOverview/queryKeys";
import { HistoricalInvoice, PaginatedHistoricalInvoices, PdfDownloadArgs } from "modules/LocationLicenseOverview/types";
import ApiInstance from "util/Api";
import { IBackendQueryKey } from "util/keyFactory";

type PaginatedInvoice = {
  location: number;
  data: Record<number, PaginatedHistoricalInvoices>;
};

type PaginatedInvoicesType = {
  data: PaginatedInvoice[];
};

type InvoicesResponse = {
  data: PaginatedHistoricalInvoices;
};

export const downloadInvoice = async (requestData: PdfDownloadArgs) => {
  const response = await ApiInstance.post("/invoices/download", requestData, ENV.exactBaseUrl, { responseType: "blob" });
  return response;
};

export const useInvoices = (paginationUrl = "", currentInvoicePage = 0) => {
  const { data: paginatedInvoices } = useQuery<PaginatedInvoicesType>({ queryKey: LocationLicenseOverviewKeys.paginatedInvoices, initialData: { data: [] } });
  const { selectedLocation } = useDealersLocations();
  const queryClient = useQueryClient();

  const getSortedResults = (data: HistoricalInvoice[]) => [...data].sort((a, b) => moment(b.Modified, "DD/MM/YYYY").diff(moment(a.Modified, "DD/MM/YYYY")));

  const updatePaginatedInvoices = (data: PaginatedHistoricalInvoices) => {
    const existingLocation = paginatedInvoices?.data.find(element => element.location === selectedLocation?.id);

    if (!existingLocation) {
      const newLocation = { location: selectedLocation?.id, data: { [currentInvoicePage]: data } };
      return { data: [...(paginatedInvoices?.data ?? []), newLocation] };
    }

    const updatedData = { ...paginatedInvoices };
    const existingLocationIndex = updatedData.data.findIndex(element => element.location === selectedLocation?.id);

    if (existingLocationIndex !== -1) {
      updatedData.data[existingLocationIndex] = { ...existingLocation, data: { ...existingLocation.data, [currentInvoicePage]: data } };
    }

    return { data: updatedData.data };
  };

  const getInvoices = async ({ queryKey }: QueryFunctionContext<ReadonlyArray<IBackendQueryKey>>) => {
    if (paginatedInvoices) {
      const existingLocationData = paginatedInvoices.data.find(element => element.location === selectedLocation?.id);

      if (existingLocationData?.data[currentInvoicePage]) {
        return existingLocationData?.data[currentInvoicePage] as PaginatedHistoricalInvoices;
      }
    }

    const { baseUrl, endpoint, params } = queryKey[0];
    const response: InvoicesResponse = await ApiInstance.post(endpoint, params, baseUrl);
    const { data } = response;

    queryClient.setQueryData(LocationLicenseOverviewKeys.paginatedInvoices, updatePaginatedInvoices(data));

    return {
      __next: data.__next,
      results: getSortedResults(data.results)
    };
  };

  const { data, isLoading, error } = useQuery({
    queryKey: LocationLicenseOverviewKeys.invoices({
      dealer_location_id: selectedLocation?.id,
      pagination_url: paginationUrl || undefined,
      page_index: currentInvoicePage
    }),
    queryFn: getInvoices,
    enabled: !!selectedLocation?.id && !!selectedLocation?.exact_account_id
  });

  if (error) toast.error(error.message);

  return {
    invoices: data,
    isLoadingInvoices: isLoading,
    paginatedInvoices
  };
};
