import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { QuestionResultData } from "components";
import SnoozeModal from "components/SnoozeModal";
import { useDealersLocations } from "hooks";
import { LeadsSnoozed } from "models";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { useGetDepartmentOptions } from "modules/LeadsDashboard/hooks";
import { SnoozedLeadsFilters, SnoozedLeadsTable } from "modules/LeadsDashboard/Leads/SnoozedLeads/components";
import { useGetSnoozedFilters } from "modules/LeadsDashboard/Leads/SnoozedLeads/hooks";
import { LeadsQueryKeys } from "modules/LeadsDashboard/queryKeys";
import { useGetTagsQuery, useLeadsSnoozedQuery } from "modules/LeadsDashboard/service";
import { groupSnoozeItems } from "modules/LeadsDashboard/util";

export const SnoozedLeads = () => {
  const queryClient = useQueryClient();
  const { locations } = useDealersLocations();
  const tags = useGetTagsQuery();
  const { snoozedFilters } = useGetSnoozedFilters();
  const { refetch } = useLeadsSnoozedQuery(snoozedFilters);
  const { departmentOptions } = useGetDepartmentOptions();
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);

  const snoozedLeadsData = queryClient.getQueryData<LeadsSnoozed>([LeadsQueryKeys.listSnoozed]);
  const normalizedSnoozedData = groupSnoozeItems(snoozedLeadsData?.items || []);

  useEffect(() => {
    const hasDealerLocationSelected = snoozedFilters.location_ids?.length > 0 || snoozedFilters.dealer_ids?.length > 0;
    const hasAppointmentDateRangeSelected = snoozedFilters.appointment_date_from && snoozedFilters.appointment_date_from;
    const hasSnoozeDateRangeSelected = snoozedFilters.snooze_date_from && snoozedFilters.snooze_date_to;
    const hasDateRangeSelected = hasAppointmentDateRangeSelected || hasSnoozeDateRangeSelected;

    if (hasDealerLocationSelected && hasDateRangeSelected) refetch();
  }, [snoozedFilters]);

  const onSnoozeDelete = (questionResultId: number) => {
    const snoozedItemsWithoutDeleted = normalizedSnoozedData.filter(element => element.question_result_id !== questionResultId);

    if (snoozedItemsWithoutDeleted.length !== normalizedSnoozedData.length)
      queryClient.setQueryData([LeadsQueryKeys.listSnoozed], { ...snoozedLeadsData, items: snoozedItemsWithoutDeleted, nb_items: snoozedItemsWithoutDeleted.length });
  };

  return (
    <>
      <SnoozedLeadsFilters filters={snoozedFilters} tagsOptions={tags} departmentOptions={departmentOptions} />
      <SnoozedLeadsTable
        locations={locations}
        filters={snoozedFilters}
        setModalData={setModalData}
        snoozedData={normalizedSnoozedData}
        nbPages={snoozedLeadsData?.nb_pages || 0}
        nbItems={snoozedLeadsData?.nb_items || 0}
      />
      <SnoozeModal
        size="large"
        open={modalData !== null}
        onClose={() => setModalData(null)}
        closeOnDimmerClick
        data={modalData}
        onDelete={(questionResultId: number) => onSnoozeDelete(questionResultId)}
        onSave={() => refetch()}
      />
    </>
  );
};
