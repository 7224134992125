import ENV from "config/Env";
import { UPDATE_TYPE, backendQueryKey, localQueryKey } from "util/keyFactory";

const component = "LeadsDashboard";

type ChecklistQueryKeys = {
  dealer_ids: number[] | null;
  location_ids: number[] | null;
};

export const LeadsQueryKeys = {
  listCars: backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.appointmentBaseURL, endpoint: "/leads/cars" }),
  listSnoozed: backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.appointmentBaseURL, endpoint: "/leads/snoozed" }),
  listNotSnoozed: backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.appointmentBaseURL, endpoint: "/leads/not_snoozed" }),
  tags: backendQueryKey({ updateType: UPDATE_TYPE.Static, component, baseUrl: ENV.systemBaseURL, endpoint: "/tags/list" }),
  checklists: ({ dealer_ids, location_ids }: ChecklistQueryKeys) => [
    backendQueryKey({
      updateType: UPDATE_TYPE.Static,
      component,
      baseUrl: ENV.dealerBaseUrl,
      endpoint: "/checklists/list",
      params: { dealer_ids, location_ids }
    })
  ],
  snoozedFilters: [localQueryKey({ component, data: "snoozedFilters" })] as const,
  notSnoozedFilters: [localQueryKey({ component, data: "notSnoozedFilters" })] as const,
  carsFilters: [localQueryKey({ component, data: "carsFilters" })] as const,
  leadsPageType: [localQueryKey({ component, data: "leadsPageType" })] as const
};
