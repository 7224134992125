/* eslint-disable @typescript-eslint/no-use-before-define */
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import { WebSocketComponent } from "components/WebSocket";
import { Auth } from "models";
import { QUERY_KEY_COMPONENTS, queryKeys, staticLocalKey } from "util/keyFactory";

export const SessionManager = () => {
  const queryClient = useQueryClient();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const authInfo = queryClient.getQueryData<Auth>(staticLocalKey(QUERY_KEY_COMPONENTS.AuthInfo));
  const tokenExpirationTime = new Date(authInfo?.expire_at || "").getTime();

  const handleTokenExpiration = () => {
    const timeRemaining = tokenExpirationTime - Date.now();
    if (timeRemaining <= 0) {
      WebSocketComponent.disconnect();
      queryClient.setQueryData([queryKeys.common.isTokenExpired], true);
    } else {
      setTokenExpirationTimeout();
    }
  };

  const setTokenExpirationTimeout = () => {
    const timeRemaining = tokenExpirationTime - Date.now();
    timeoutRef.current = setTimeout(handleTokenExpiration, timeRemaining);
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else {
      setTokenExpirationTimeout();
    }
  };

  useEffect(() => {
    setTokenExpirationTimeout();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [authInfo]);

  return null;
};
