import { useTranslation } from "react-i18next";
import { Button, Grid, GridColumn, GridRow, SemanticWIDTHS } from "semantic-ui-react";

import { ActionModalSelectorData } from "components";
import { Appointment, ChecklistTemplate, QuestionResult, TYRE_POSITIONS, Tyre } from "models";
import { TyreTableItem } from "modules/AppointmentDetails/components/Tyre/components";
import { ITranslation } from "util/interfaces";

type TyreTableProps = {
  data: QuestionResult[];
  template?: ChecklistTemplate | null;
  title: string;
  icon: React.ReactNode;
  onRequestDBBPreview?: (data: QuestionResult[]) => void;
  onRequestReplacement?: (data: QuestionResult) => void;
  onSelectAction: (data: ActionModalSelectorData) => void;
  appointment: Appointment;
  showAxle?: boolean;
};

export const getTyreDescription = (tyre: Tyre) => {
  return !tyre.description.includes(`${tyre.carry_capacity}/${tyre.speed}`) && !tyre.description.includes(`${tyre.carry_capacity}${tyre.speed}`)
    ? `${tyre.description} ${tyre.carry_capacity}${tyre.speed}`
    : tyre.description;
};

export const size = (tyre: Tyre) => `${tyre.width}-${tyre.height}-R${tyre.size}`;

const TyreTable = ({ data, template, title, icon, onRequestDBBPreview, onRequestReplacement, appointment, onSelectAction, showAxle }: TyreTableProps) => {
  const t = useTranslation().t as ITranslation;

  if (!data.length) return null;

  const checkListColumns = [
    { header: t("v8_question").message || "Question", width: 2 },
    { header: t("v8_size").message || "Size", width: 2 },
    { header: t("v8_type").message || "Type", width: 4 },
    { header: t("v8_brand").message || "Brand", width: 1 },
    { header: t("v8_profile").message || "Profile", width: 1 },
    { header: t("v8_price").message || "Price", width: 1 },
    { header: t("v8_remarks").message || "Remarks", width: 2 },
    { header: "", width: 1 }
  ];

  const handleDBBPreview = () => {
    onRequestDBBPreview?.(data);
  };

  const divideByAxle = (data: QuestionResult[]) => {
    const axleGroup: Record<string, QuestionResult[]> = {};
    let spareTyreCount = 0;

    data.forEach(item => {
      const axle = item.truck_axle;
      let axleName = `${t("v8_axle").message || "Axle"} ${axle}`;
      if (axle !== undefined) {
        if (item.tyre_position === TYRE_POSITIONS.TruckSpare) {
          spareTyreCount++;
          axleName = `${t("v8_spare_tyre").message || "Spare Tyre"} ${spareTyreCount}`;
        }
        if (axleGroup[axleName] === undefined) {
          axleGroup[axleName] = [item];
        } else {
          axleGroup[axleName].push(item);
        }
      }
    });
    return axleGroup;
  };

  const axelGroup = divideByAxle(data);

  return (
    <div className="TyreTable">
      <div className="tyre-title-container">
        <div className="tyre-title">
          {icon}
          <p>{title}</p>
        </div>
        <Button basic color="light" size="tiny" onClick={handleDBBPreview}>
          <p>{t("v8_tire_labels").message || "Tire Labels"}</p>
        </Button>
      </div>
      <Grid columns={16}>
        <GridRow className="header">
          {checkListColumns.map(item => (
            <GridColumn key={item.header} width={item.width as SemanticWIDTHS}>
              <p>{item.header}</p>
            </GridColumn>
          ))}
        </GridRow>
        {Object.keys(axelGroup).map(axle => {
          const tyreElements: QuestionResult[] = axelGroup[axle];

          return (
            <>
              {showAxle && (
                <GridRow key={axle} className="axle-header">
                  <GridColumn width={16}>
                    <p>{axle}</p>
                  </GridColumn>
                </GridRow>
              )}
              {tyreElements.map(item => (
                <TyreTableItem
                  key={item.id}
                  data={item}
                  template={template}
                  onRequestReplacement={onRequestReplacement}
                  appointment={appointment}
                  onSelectAction={onSelectAction}
                />
              ))}
            </>
          );
        })}
      </Grid>
    </div>
  );
};

export default TyreTable;
