import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Popup } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AttachmentPreview, DATE_FORMATS } from "components";
import { Appointment, CheckInRemark, DiagnoseOverviewRemark, KeylockerRemark, KioskRemark, QuestionResult } from "models";
import { getExtensionFromURL } from "util/common";
import { ITranslation } from "util/interfaces";

type CommunicationRemarksProps<T> = {
  title: string;
  remarks?: T[];
  canCreateIntervention: boolean;
  onSelect: (data: ActionModalSelectorData, remark: T) => void;
  isKeyLocker?: boolean;
  appointment: Appointment;
  onToggleHandled: (remark: T) => void;
};

export const CommunicationRemarks = <T extends CheckInRemark | DiagnoseOverviewRemark | KeylockerRemark | KioskRemark>({
  title,
  remarks,
  canCreateIntervention,
  onSelect,
  onToggleHandled,
  isKeyLocker,
  appointment
}: CommunicationRemarksProps<T>) => {
  if (!remarks?.length) return null;

  const [visibleCustomerActionAttachmentId, setVisibleCustomerActionAttachmentId] = useState<number | null>();
  const t = useTranslation().t as ITranslation;

  const showMedia = (id: number) => setVisibleCustomerActionAttachmentId(current => (current === id ? null : id));

  return (
    <div className="CustomerActions-items">
      {remarks
        .sort((a, b) => a.id - b.id)
        .map(remark => {
          if (!remark) return null;
          const isDiagnoseRemark = appointment.customer_communication?.diagnose_overview_remarks?.some(diagnoseRemark => diagnoseRemark.id === remark.id);

          const actionModelData = {
            title: remark.title,
            raw: remark.description,
            solution: "",
            appointment_id: appointment.id,
            ...(isDiagnoseRemark ? { diagnose_overview_remark_id: remark.id } : { check_in_remark_id: remark.id })
          };

          const attachments = remark.attachments?.map(attachment => ({
            id: attachment.id,
            url: attachment.url,
            name: attachment.name,
            created_on: attachment.created_on,
            updated_on: attachment.updated_on,
            type: getExtensionFromURL(attachment.url)
          }));

          const isRemarkAlreadyConvertedToIntervention = appointment?.interventions?.some(({ diagnose_overview_remark_id, check_in_remark_id }) =>
            [diagnose_overview_remark_id, check_in_remark_id].includes(remark.id)
          );

          return (
            <Grid key={remark.id} className="actions-container">
              <Grid.Column width={13}>
                <div className={`icon-container ${isKeyLocker ? "green" : "blue"}`}>
                  <Popup
                    wide
                    disabled={!remark.customer_name}
                    basic
                    content={
                      <div className="InfoPopup">
                        <div className="InfoTitle">{title}</div>
                        <div className="InfoDescription">
                          {t("v8_made_by").message || "Made by"} {remark.customer_name}
                        </div>
                        <div className="InfoContent">
                          <span>{t("v8_on").message || "On"}</span> {`${moment(remark.created_on).format(DATE_FORMATS.dateMonthYearTime)}`}
                        </div>
                      </div>
                    }
                    trigger={<Icon className={isKeyLocker ? "key" : "note sticky"} />}
                  />
                </div>
                {remark.title && <p className="title">{remark.title}</p>}
                {remark.description && <p className="description">{remark.description}</p>}
              </Grid.Column>
              <Grid.Column width={3}>
                <div className="end-grid">
                  {!isKeyLocker && !!remark.attachments?.length && (
                    <div onClick={() => showMedia(remark.id)} className="image-action">
                      <Icon className="images grey pointer" size="small" />
                      <p>
                        <small>{remark.attachments.length}</small>
                      </p>
                    </div>
                  )}
                  <div className="status-container">
                    <div className="changeHandleStatus" onClick={() => onToggleHandled(remark)}>
                      <Icon className={remark.receptionist_handled ? "check circle outline green" : "circle xmark outline red"} />
                    </div>

                    {canCreateIntervention && (
                      <ActionModalSelector
                        isConvertToInterventionButtonDisabled={isRemarkAlreadyConvertedToIntervention}
                        data={actionModelData as QuestionResult}
                        attachments={attachments}
                        onSelect={data => onSelect(data, remark)}
                        onlyIntervention
                        appointment={appointment}
                      />
                    )}
                  </div>
                </div>
              </Grid.Column>
              {visibleCustomerActionAttachmentId === remark.id && (
                <Grid.Column width={16}>
                  <AttachmentPreview attachments={attachments} />
                </Grid.Column>
              )}
            </Grid>
          );
        })}
    </div>
  );
};
